<!-- 用户管理 -->
<template>
	<div class="app-container" ref="appContainer">
		<div class="container sys-manage-menu">
			<div class="top-bar">
				<el-form :inline="true" class="demo-form-inline">
					<el-form-item label="用户名">
						<el-input v-model="reqData.username" placeholder="用户名"></el-input>
					</el-form-item>
					<el-form-item label="手机号">
						<el-input v-model="reqData.phone" placeholder="手机号"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="handleQuery">查询</el-button>
						<el-button type="primary" @click="addUser">添加用户</el-button>
					</el-form-item>
				</el-form>
			</div>
			<div class="center-content" ref="tbContainer">
				<el-table ref="tbUsers" :data="tbUsers" style="width: 100%;" border :height="tbHeight" stripe default-expand-all
					:highlight-current-row="true" :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
					<el-table-column type="index" label="序号" width="100" align="center">
					</el-table-column>
					<el-table-column prop="username" label="用户名" align="center">
					</el-table-column>
					<el-table-column prop="phone" label="手机号" align="center">
					</el-table-column>
					<el-table-column prop="roleName" label="角色" align="center">
					</el-table-column>
					<el-table-column label="操作" align="center" width="260">
						<template slot-scope="scope">
							<el-button size="medium" type="text" @click.stop="editUser(scope.row)">编辑</el-button>
							<el-button size="medium" type="text" @click.stop="deleteUser(scope.row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<el-pagination class="page-bar" @size-change="changePageSize" @current-change="changeCurrentPage"
				:current-page="reqData.page" :page-size="reqData.limit" :total="totalCount"
				:page-sizes="[10, 20, 30, 40, 50]" layout="total, sizes, prev, pager, next">
			</el-pagination>
		</div>

		<el-dialog :title="addOrEditDiaTitle" :visible.sync="showAddOrEditDia" :close-on-click-modal="false"
			width="500px">
			<div>
				<el-form :model="formUser" :rules="rules" ref="formUser" label-width="90px">
					<el-form-item prop="role_id" label="角色">
						<el-select v-model="formUser.role_id" placeholder="请选择角色" style="width: 100%;">
							<el-option v-for="item in roles" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item prop="username" label="用户名">
						<el-input v-model="formUser.username" size="large" placeholder="请输入用户名"></el-input>
					</el-form-item>
					<el-form-item prop="phone" label="手机号">
						<el-input v-model="formUser.phone" size="large" placeholder="请输入手机号"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancelAddOrEdit">取 消</el-button>
				<el-button type="primary" @click="confirmAddOrEdit">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				loadingData: true,
				
				reqData: {
					page: 1,
					limit: 10,
					phone: '',
					username: ''
				},
				tbUsers: [],
				totalCount: 0,
				tbHeight: 600,
				roles: [],
				addOrEditDiaTitle: '新增用户',
				showAddOrEditDia: false,
				towns: [],
				depart: [],
				deptAll: [],
				formUser: {
					uuid: '',
					username: '',
					phone: '',
					role_id: 0
				},
				rules: {
					username: [{
						required: true,
						message: '请输入用户名',
						trigger: 'blur'
					}],
					phone: [{
							required: true,
							message: '请输入手机号',
							trigger: 'blur'
						},
						{
							validator: (rule, value, callback) => {
								if (/^1[3-9]\d{9}$/.test(value)) {
								   callback();
								} else {
								   callback(new Error('手机号不正确'));
								}
							},
							trigger: 'blur'
						}
					],
				}
			}
		},
		mounted() {
			this.loadData();

			this.getRoles();

			this.$erd.listenTo(this.$refs.appContainer, (element) => {
				this.tbHeight = 100;
				this.$nextTick(() => {
					this.tbHeight = this.$refs.tbContainer.offsetHeight;
				})
			})
		},
		methods: {
			handleQuery() {
				this.loadData();
			},
			// 分页
			changePageSize(val) {
				this.reqData.limit = val;
				this.reloadData();
			},
			changeCurrentPage(val) {
				this.reqData.page = val;
				this.loadData();
			},
			reloadData() {
				this.tbUsers = [];
				this.reqData.page = 1;
				this.loadData();
			},
			changeDept(val) {
				this.deptId = val;
				this.formUser.deptId = val;
				if (val) {
					this.$set(this.formUser, this.formUser.deptId, val)
				} else {
					this.$set(this.formUser, this.formUser.deptId, '')
				}
			},
			getRoles() {
				this.$http.post('/SysManage/Role/GetRoles').then(res => {
					if (res.data.errcode == 0) {
						this.roles = res.data.result;
					}
				})
			},
			loadData() { // 获取用户
				this.tbUsers = [];
				this.$http.post('/SysManage/User/List', this.reqData).then(res => {
					this.loadingData = false;
					if (res.data.errcode == 0) {
						this.totalCount = res.data.result.count;
						this.tbUsers = res.data.result.list;
					}
				})
			},
			addUser() { // 添加用户
				this.deptId = '';
				this.formUser = {
					uuid: '',
					username: '',
					phone: '',
					role_id: this.roles[0].id
				};
				this.addOrEditDiaTitle = '添加用户';
				this.showAddOrEditDia = true;
				this.$nextTick(() => {
					this.$refs.formUser.clearValidate();
				})
			},
			editUser(user) { // 编辑用户
				this.formUser = {
					username: '',
					deptId: ''
				}
				user.deptId = String(user.deptId)
				this.deptId = user.deptId;

				let pro = this.deptAll.find((item) => {
					return item.id === user.deptId
				});
				if (pro != null) {
					if (pro.pid == '11111') {
						user.townId = user.deptId;
						user.deptId = '';
					} else if (pro.pid == '0') {
						user.townId = '';
						user.deptId = '';
					} else {
						this.depart = this.deptAll.filter(d => d.pid == pro.pid);

						user.townId = pro.pid;
					}
				}

				this.formUser = JSON.parse(JSON.stringify(user));

				this.addOrEditDiaTitle = '编辑用户';
				this.showAddOrEditDia = true;
				this.$refs.tbUsers.setCurrentRow(user)
			},
			deleteUser(user) { // 删除用户
				this.$refs.tbUsers.setCurrentRow(user)
				this.$confirm('确定要删除该用户吗', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					closeOnClickModal: false,
				}).then(() => {
					this.$http.post('/SysManage/User/Delete', {
						uid: user.uuid
					}).then(res => {
						if (res.data.errcode == 0) {
							let idx = this.tbUsers.findIndex(u => u.uuid == user.uuid);
							this.tbUsers.splice(idx, 1);

							this.$message({
								message: '删除成功！',
								type: 'success'
							});
						} else {
							this.$message({
								message: '删除失败！',
								type: 'error'
							})
						}
					})
				}).catch(() => {

				});
			},
			cancelAddOrEdit() { // 取消，关闭对话框
				// this.$refs.formUser.clearValidate();
				this.showAddOrEditDia = false;
			},
			confirmAddOrEdit() { // 保存
				this.$refs.formUser.validate((valid) => {
					if (valid) {
						//if (this.formUser.deptId == null || this.formUser.deptId == undefined) {
						//    this.formUser.deptId = this.formUser.townId;
						//}

						this.formUser.deptId = this.deptId;

						if (this.formUser.uuid) {
							this.$http.post('/SysManage/User/Update', this.formUser).then(res => {
								if (res.data.errcode == 0) {
									this.$message({
										message: '修改成功',
										type: 'success'
									});

									this.loadData();

									this.cancelAddOrEdit();
								} else {
									this.$message({
										message: '修改失败',
										type: 'error'
									});
								}
							})
						} else {
							this.$http.post('/SysManage/User/Add', this.formUser).then(res => {
								if (res.data.errcode == 0) {
									this.$message({
										message: '添加成功',
										type: 'success'
									});

									this.loadData();

									this.cancelAddOrEdit();
								} else {
									this.$message({
										message: '添加失败',
										type: 'error'
									});
								}
							})
						}
					} else {
						return false;
					}
				});
			},
		}
	}
</script>

<style lang="less" scoped>
	.app-container {
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	.container {
		flex: 1;
		display: flex;
		flex-direction: column;
		/*height: 100%;*/
		padding: 18px 8px 8px;
		
		.center-content {
			flex: 1;
		}
	}

	.top-bar {
		/*margin-bottom: 15px;*/
	}

	.center-content {
		flex: 1;
	}

	.center-content .el-scrollbar__wrap {
		overflow-x: hidden;
	}
</style>
