<!-- 智能管理 - 首页 -->
<template>
	<div class="app-container" ref="appContainer">
		<div class="container">
			<div class="top-bar">
				<el-button type="primary" @click="addRole">新增排班</el-button>
			</div>
			<div class="center-content" ref="tbContainer">

				<el-table ref="tbRoles" :data="tbRoles" border stripe class="table" :height="tbHeight"
					:highlight-current-row="true" :header-cell-style="{textAlign: 'center'}"
					:cell-style="{ textAlign: 'center' }">
					<el-table-column label="序号" type="index" show-overflow-tooltip width="50"></el-table-column>
					<el-table-column prop="title" label="班次名称" width="180"></el-table-column>

					<el-table-column prop="in_time" label="上午签到"></el-table-column>

					<el-table-column prop="in_signin" label="上午签退"></el-table-column>

					<el-table-column prop="out_time" label="下午签到"></el-table-column>

					<el-table-column prop="out_signin" label="下午签退"></el-table-column>

					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-button size="mini" type="success" @click="editRole(scope.row)">编辑</el-button>
							<el-button size="mini" type="danger" @click="deleteRole(scope.row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>

			</div>
		</div>

		<!-- 弹窗区域 -->
		<el-dialog :title="addOrEditDiaTitle" :visible.sync="showAddOrEditDia" :close-on-click-modal="false"
			@close="closeAddOrEditDia" width="600px">
			<div style="padding-right:10px">
				<el-form :model="formRole" :rules="rules" ref="formRole" label-width="90px">
					<el-row>
						<el-col :span="24">
							<div class="grid-content bg-purple">
								<el-form-item prop="title" label="班次名称">
									<el-input v-model="formRole.title" :clearable="true" size="large"
										placeholder="请输入班次名称"></el-input>
								</el-form-item>
							</div>
						</el-col>
					</el-row>

					<el-row>
						<el-col :span="12">
							<div class="grid-content bg-purple">
								<el-form-item prop="in_time" label="上午签到">
									<el-time-select placeholder="请选择时间点" style="width: 100%;" v-model="formRole.in_time"
										:picker-options="{start: '05:30',step: '00:05',end: '18:30'}">
									</el-time-select>
								</el-form-item>
							</div>
						</el-col>
						<el-col :span="12">
							<div class="grid-content bg-purple">
								<el-form-item prop="in_signin" label="上午签退">
									<el-time-select placeholder="请选择时间点" style="width: 100%;"
										v-model="formRole.in_signin"
										:picker-options="{start: '08:30',step: '00:05',end: '22:30'}">
									</el-time-select>
								</el-form-item>
							</div>
						</el-col>
					</el-row>

					<el-row>
						<el-col :span="12">
							<div class="grid-content bg-purple">
								<el-form-item prop="out_time" label="下午签到">
									<el-time-select placeholder="请选择时间点" style="width: 100%;"
										v-model="formRole.out_time"
										:picker-options="{start: '08:30',step: '00:05',end: '22:30'}">
									</el-time-select>
								</el-form-item>
							</div>
						</el-col>
						<el-col :span="12">
							<div class="grid-content bg-purple">
								<el-form-item prop="out_signin" label="下午签退">
									<el-time-select placeholder="请选择时间点" style="width: 100%;"
										v-model="formRole.out_signin"
										:picker-options="{start: '08:30',step: '00:05',end: '22:30'}">
									</el-time-select>
								</el-form-item>
							</div>
						</el-col>
					</el-row>
				</el-form>
			</div>

			<span slot="footer" class="dialog-footer">
				<el-button @click="showAddOrEditDia = false;">取 消</el-button>
				<el-button type="primary" @click="confirmAddOrEdit">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				loadingData: true,
				tbRoles: [],
				tbHeight: 300,
				addOrEditDiaTitle: '新增班次',
				showAddOrEditDia: false,

				formRole: {
					uuid: '',
					title: '',
					in_time: '',
					out_time: '',
					out_signin: '',
					in_signin: ''
				},
				rules: {
					title: [{
						required: true,
						message: '请输入班次名称',
						trigger: 'blur'
					}],
					in_time: [{
						required: true,
						message: '请选择上午签到时间点',
						trigger: 'blur'
					}],
					out_time: [{
						required: true,
						message: '请选择下午签到时间点',
						trigger: 'blur'
					}],
					in_signin: [{
						required: true,
						message: '请选择上午签退时间点',
						trigger: 'blur'
					}],
					out_signin: [{
						required: true,
						message: '请选择下午签退时间点',
						trigger: 'blur'
					}]
				},
			}
		},
		mounted() {
			this.loadData();

			this.$nextTick(() => {
				this.tbHeight = this.$refs.tbContainer.offsetHeight;
			})
		},
		methods: {
			loadData() { // 获取班次设置
				this.$http.post('/Arrange/Arrange/GetArrange').then(res => {
					if (res.data.errcode == 0) {
						this.tbRoles = res.data.result;
					}
				})
			},
			addRole() { // 添加排班班次
				this.formRole = {
					uuid: '',
					title: '',
					in_time: '',
					out_time: '',
					in_signin: '',
					out_signin: ''
				};
				this.addOrEditDiaTitle = '新增排班班次';
				this.showAddOrEditDia = true;
			},
			editRole(role) { // 编辑排班班次
				this.formRole = JSON.parse(JSON.stringify(role));
				this.addOrEditDiaTitle = '编辑排班班次';
				this.showAddOrEditDia = true;
				this.$refs.tbRoles.setCurrentRow(role)
			},
			deleteRole(role) {
				this.$refs.tbRoles.setCurrentRow(role)
				this.$confirm('确定要删除该班次吗', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					closeOnClickModal: false,
				}).then(() => {
					this.$http.post('/Arrange/Arrange/DeleteArrange', {
						id: role.uuid
					}).then(res => {
						if (res.data.errcode == 0) {
							let idx = this.tbRoles.findIndex(r => r.id == role.uuid);
							this.tbRoles.splice(idx, 1);

							this.$message({
								message: '删除成功！',
								type: 'success'
							});
						} else {
							this.$message({
								message: res.data.errmsg,
								type: 'error'
							});
						}
					})
				}).catch(() => {

				});
			},
			closeAddOrEditDia() { // 关闭对话框
				this.formRole = {
			 	uuid: '',
					title: '',
					in_time: '',
					out_time: '',
					in_signin: '',
					out_signin: ''
				};
			},
			confirmAddOrEdit() {
				this.$refs.formRole.validate((valid) => {
					if (valid) {
						if (this.formRole.uuid != '') {
							this.$http.post('/Arrange/Arrange/UpdateArrange', this.formRole).then(res => {
								if (res.data.errcode == 0) {
									this.$message({
										message: '修改成功',
										type: 'success'
									});

									this.loadData();

									this.showAddOrEditDia = false;
								} else {
									this.$message({
										message: res.data.errmsg,
										type: 'error'
									});
								}
							})
						} else {
							this.$http.post('/Arrange/Arrange/AddArrange', this.formRole).then(res => {
								if (res.data.errcode == 0) {
									this.$message({
										message: '添加成功',
										type: 'success'
									});

									this.loadData();

									this.showAddOrEditDia = false;
								} else {
									this.$message({
										message: res.data.errmsg,
										type: 'error'
									});
								}
							})
						}
					} else {
						return false;
					}
			 });
			},
		},
	}
</script>

<style lang="less" scoped>
	.app-container {
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	.container {
		flex: 1;
		display: flex;
		flex-direction: column;
		/*height: 100%;*/
		padding: 18px 8px 8px;

		.center-content {
			flex: 1;
		}
	}

	// .center-content {
	//     flex: 1;

	//     .el-scrollbar__wrap {
	//         overflow-x: hidden;
	//     }
	// }

	.user-statistics {
		display: flex;
		align-items: center;
		font-size: 16px;
		margin-bottom: 20px;

		.count {
			color: #f56c6c;
			font-weight: bold;
		}

		.user-total {}

		.user-today {
			margin-left: 20px;
		}
	}
</style>
