<!-- 智能管理 - 部门管理 -->
<template>
	<div class="app-container" ref="appContainer">
		<el-container>
			<el-aside class="left-side">
				<el-scrollbar style="height:100%;">
					<el-tree class="tree-dept" ref="treeDept" :data="depts" :props="defaultProps"
						:highlight-current="true" :default-expand-all="true" node-key="id" @node-click="treeClick">
						<span class="custom-tree-node" slot-scope="{ node, data }">
							<i style="color:#ff6a00" class="el-icon-school"></i>
							<span>{{ node.label }}</span>
		 			</span>
					</el-tree>
				</el-scrollbar>
			</el-aside>
			<el-main class="center">
				<div class="container">
					<div class="top-bar">
						<el-button type="primary" @click="addDept">新增部门</el-button>
						<el-button type="primary" @click="addDept(1)">新增下级部门</el-button>
					</div>
					<div class="center-content" ref="tbContainer">
						<el-table v-loading="loadingData" :data="tbDepts" border class="table" :height="tbHeight">
							<el-table-column prop="zzmc" label="部门名称">
							</el-table-column>
							<el-table-column prop="fzzmc" label="父部门名称">
							</el-table-column>
							<el-table-column prop="createTime" label="添加时间">
							</el-table-column>
							<el-table-column prop="creator" label="添加人">
							</el-table-column>
							<el-table-column label="状态" width="100">
								<template slot-scope="scope">
									<i v-if="scope.row.state == 0" class="el-icon-circle-check"
										style="color: #67C23A; font-size: 18px;"></i>
									<i v-else class="el-icon-circle-close"
										style="color: #F56C6C; font-size: 18px; "></i>
								</template>
							</el-table-column>
							<el-table-column label="操作" width="150">
								<template slot-scope="scope">
									<el-button size="mini" type="success" @click="editDept(scope.row)">编辑</el-button>
									<el-button size="mini" type="danger" @click="deleteDept(scope.row)">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
			</el-main>
		</el-container>

		<!-- 弹窗区域 -->
		<el-dialog :title="addOrEditDiaTitle" :visible.sync="showAddOrEditDia" :close-on-click-modal="false"
			width="500px">
			<div>
				<el-form :model="formDept" :rules="rules" ref="formDept" label-width="90px">
					<el-form-item label="父部门" v-show="formDept.fzzmc.length >0">
						<el-input v-model="formDept.fzzmc" size="large" placeholder="请输入部门名称" :disabled="true">
						</el-input>
					</el-form-item>
					<el-form-item prop="zzmc" label="部门名称">
						<el-input v-model="formDept.zzmc" size="large" maxlength="50" placeholder="请输入部门名称"></el-input>
					</el-form-item>
					<el-form-item prop="zzcode" label="部门代码">
						<el-input v-model="formDept.zzcode" size="large" maxlength="10" placeholder="请输入部门代码">
						</el-input>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancelAddOrEdit">取 消</el-button>
				<el-button type="primary" @click="confirmAddOrEdit">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				depts: [],
				defaultProps: {
					children: 'children',
					label: 'zzmc'
				},

				loadingData: false,
				reqData: {
					page: 1,
					limit: 10
				},
				totalCount: 0,
				tbDepts: [],
				tbHeight: 300,

				showIconPicker: false,
				iconsData: [],

				addOrEditDiaTitle: '新增部门',
				showAddOrEditDia: false,

				clickedTbDept: '',
				formDept: {
					id: '',
					zzmc: '',
					remark: '',
					zzcode: '',
					fzzmc: '',
					state: 0
				},
				rules: {
					zzmc: [{
						required: true,
						message: '请输入部门名称',
						trigger: 'blur'
					}],
					zzcode: [{
						required: true,
						message: '请输入部门代码',
						trigger: 'blur'
					}],
				}
			}
		},
		mounted() {
			this.loadData();

			this.$erd.listenTo(this.$refs.appContainer, (element) => {
				this.tbHeight = 100;
				this.$nextTick(() => {
					this.tbHeight = this.$refs.tbContainer.offsetHeight;
				})
			})
		},
		methods: {
			reloadData() {
				this.tbDepts = [];
				this.reqData.page = 1;
				this.loadData();
			},
			loadData() { // 获取所有部门
				this.$http.post('/BasicData/Department/GetDepartmentTree').then(res => {
					if (res.data.errcode == 0) {
						this.depts = res.data.result;
					}
				})
			},
			treeClick(data, node, e) {
				this.tbDepts = [];

				let {
					children,
					...tmp
				} = data;
				this.tbDepts.push(tmp);

				if (data.children) {
					for (let i = 0, len = data.children.length; i < len; i++) {
						let {
							children,
							...tmp
						} = data.children[i];
						tmp.fzzmc = data.zzmc;
						this.tbDepts.push(tmp);
					}
				}
			},
			addDept(type) { // 添加部门
				this.formDept = {
					id: '',
					zzmc: '',
					zzcode: '',
					fzzcode: '',
					fzzmc: '',
					state: 0
				};
				if (type == 1) {
					let currNode = this.$refs.treeDept.getCurrentNode();
					console.log(currNode, "currNode")
					if (!currNode) {
						this.$message({
							message: '请先选择父部门！',
							type: 'info',
							duration: 1000
						})
						return;
					}
					this.formDept.fzzcode = currNode.zzcode;
					this.formDept.fzzmc = currNode.zzmc;
					this.addOrEditDiaTitle = "新增子部门";
				} else {
					this.addOrEditDiaTitle = '新增部门';
				}

				this.showAddOrEditDia = true;
				this.$nextTick(() => {
					this.$refs.formDept.resetFields();
				})
			},

			editDept(dept) { // 编辑机构
				this.clickedTbDept = dept;
				this.formDept = JSON.parse(JSON.stringify(dept));
				this.addOrEditDiaTitle = '编辑部门';
				this.showAddOrEditDia = true;
			},

			deleteDept(dept) { // 删除菜单
				this.$confirm('确定要删除该部门吗', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					closeOnClickModal: false,
				}).then(() => {
					this.$http.post('/BasicData/Department/DeleteDept', {
						id: dept.id,
						zzcode: dept.zzcode
					}).then(res => {
						if (res.data.errcode == 0) {
							//this.loadData();
							let idx = this.tbDepts.findIndex(d => d.id == dept.id);
							this.tbDepts.splice(idx, 1);

							this.$refs.treeDept.remove(dept.id);

							this.$message({
								message: '删除成功！',
								type: 'success'
							});
						} else {
							this.$message({
								message: '删除失败！',
								type: 'error'
							})
						}
					})
				}).catch(() => {

				});
			},
			cancelAddOrEdit() { // 取消，关闭对话框
				this.$refs.formDept.resetFields();
				this.showAddOrEditDia = false;
			},
			confirmAddOrEdit() { // 保存菜单信息
				this.$refs.formDept.validate((valid) => {
					if (valid) {
						if (this.formDept.id != '') {
							this.$http.post('/BasicData/Department/UpdateDept', this.formDept).then(res => {
								console.log(res, "res")
								if (res.data.errcode == 0) {
									this.$message({
										message: '修改成功',
										type: 'success'
									});

									this.clickedTbDept.zzmc = this.formDept.zzmc;
									this.clickedTbDept.zzcode = this.formDept.zzcode;

									this.loadData();
									let treeNode = this.$refs.treeDept.getNode(this.formDept.zzcode);
									if (treeNode) {
										treeNode.data.zzmc = this.formDept.zzmc;
										treeNode.data.zzcode = this.formDept.zzcode;
									}

									if (!this.formDept.fzzcode) {
										let depts = this.tbDepts.filter(d => d.fzzcode == this.formDept
											.zzcode);
										for (let i = 0, len = depts.length; i < len; i++) {
											depts[i].fzzmc = this.formDept.zzmc;
										}
									}

									this.cancelAddOrEdit();
									this.clickedTbDept = '';
								}
							})
						} else {
							this.$http.post('/BasicData/Department/AddDept', this.formDept).then(res => {
								if (res.data.errcode == 0) {
									this.$message({
										message: '添加成功',
										type: 'success'
									});

									this.$refs.treeDept.append(res.data.result, this.formDept.fzzcode);

									this.loadData();

									this.cancelAddOrEdit();

									if (this.formDept.fzzcode) {
										this.tbDepts.push(res.data.result)
									}
								}
							})
						}
					} else {
						return false;
					}
				});
			},
		},
	}
</script>

<style lang="less" scoped>
	.app-container {
		height: 100%;
		display: flex;
		flex-direction: column;
	}
	
	.left-side {
		::v-deep .el-scrollbar__wrap{
			overflow-x: hidden;
		}
	}

	.el-main{
		overflow: hidden;
	}
	
	.container {
		flex: 1;
		display: flex;
		flex-direction: column;
		height: 100%;
		// padding: 18px 8px 8px;

		.center-content {
			flex: 1;
		}
	}

	// .center-content {
	//     flex: 1;

	//     .el-scrollbar__wrap {
	//         overflow-x: hidden;
	//     }
	// }

	.user-statistics {
		display: flex;
		align-items: center;
		font-size: 16px;
		margin-bottom: 20px;

		.count {
			color: #f56c6c;
			font-weight: bold;
		}

		.user-total {}

		.user-today {
			margin-left: 20px;
		}
	}
</style>
