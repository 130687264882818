<!-- 智能管理 - 首页 -->
<template>
	<div class="app-container" ref="appContainer">
		<div class="container">
			<div class="top-bar">
				<div class="user-statistics">
					<div class="user-total">
						总人数：<span class="count">{{ statistics.total }}</span>
					</div>
					<div class="user-today">
						今日打卡人数：<span class="count">{{ statistics.today }}</span>
					</div>
				</div>
				<el-form :inline="true">
					<el-form-item>
						<el-date-picker v-model="s_logDate" type="daterange" range-separator="至"
							start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
							:picker-options="pickerOptions">
						</el-date-picker>
					</el-form-item>
					<el-form-item>
						<el-select v-model="reqData.banzubumen" clearable placeholder="请选择年级组">
							<el-option v-for="(item, index) in banzubumens" :key="index" :label="item.zzmc"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<!-- <el-form-item>
		                        <el-select v-model="reqData.deviceSn" clearable placeholder="请选择设备">
		                            <el-option v-for="(item, index) in equipments" :key="index"
		                                       :label="item.install_address" :value="item.deviceSn">
		                            </el-option>
		                        </el-select>
		                    </el-form-item> -->
					<el-form-item>
						<el-input v-model="reqData.username" clearable placeholder="请输入人员姓名"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="searchLog"><i class="el-icon-search"></i>查询</el-button>
						<el-button type="primary" @click="resetSearch"><i class="el-icon-refresh"></i>重置</el-button>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="exportData"><i class="el-icon-upload2"></i>导出数据</el-button>
						<el-button type="primary" @click="showMonthDataDia = true"><i class="el-icon-upload2"></i>月汇总导出
						</el-button>
					</el-form-item>
				</el-form>
			</div>

			<div class="center-content" ref="tbContainer">
				<el-table v-loading="loadingLogData" :data="tbLogs" border :height="tbHeight" stripe
					:header-cell-style="{ textAlign: 'center' }" :cell-style="{ textAlign: 'center' }"
					tooltip-effect="dark" style="width: 100%">
					<el-table-column type="index" label="序号" width="80">
					</el-table-column>
					<el-table-column prop="deviceSn" label="设备编号"> </el-table-column>
					<el-table-column prop="deviceSite" label="设备位置">
					</el-table-column>
					<el-table-column prop="banzubumen" label="所在年级组">
					</el-table-column>
					<el-table-column prop="xingming" label="人员姓名"> </el-table-column>
					<el-table-column prop="idCard" label="手机号"> </el-table-column>
					<el-table-column prop="time" label="打卡时间"> </el-table-column>
					<!-- <el-table-column prop="photoUrl" label="打卡照片">
            <template slot-scope="scope">
              <el-image
                :src="getOssImgThumb(scope.row.photoUrl, 32, 32)"
                :preview-src-list="[scope.row.photoUrl]"
              >
              </el-image>
            </template>
          </el-table-column> -->
				</el-table>
			</div>
			<el-pagination class="page-bar" @size-change="changeLogPageSize" @current-change="changeLogCurrentPage"
				:current-page="reqData.page" :page-size="reqData.limit" :total="totalLogCount"
				:page-sizes="[10, 20, 30, 40, 50]" layout="total, sizes, prev, pager, next">
			</el-pagination>
		</div>

		<el-dialog title="月汇总数据导出" :visible.sync="showMonthDataDia" :close-on-click-modal="false" width="400px"
			@close="closeMonthDataDia">
			<div style="padding-right: 20px">
				<el-form :model="reqDataMonth" ref="formMonthData" label-width="90px">
					<el-form-item label="月份" prop="month" :rules="[{ required: true, message: '请选择月份' }]">
						<el-date-picker v-model="reqDataMonth.month" type="month" placeholder="请选择月份"
							style="width: 100%" value-format="yyyy-MM" :picker-options="pickerOptions_Month">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="年级组">
						<el-select v-model="reqDataMonth.banzubumen" clearable placeholder="请选择年级组" style="width: 100%">
							<el-option v-for="(item, index) in banzubumens" :key="index" :label="item.zzmc"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="showMonthDataDia = false">取 消</el-button>
				<el-button type="primary" @click="confirmExportMonthData">导 出</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				statistics: {
					total: 0,
					today: 0,
				},
				loadingLogData: true,
				minDate: '',
				maxDate: '',
				equipments: [],
				banzubumens: [],
				reqData: {
					page: 1,
					limit: 10,
					startDate: '',
					endDate: '',
					deviceSn: '',
					banzubumen: '',
					username: '',
				},
				totalLogCount: 0,
				tbLogs: [],
				tbHeight: 300,

				selectDate: '',
				s_logDate: '',
				pickerOptions: {
					onPick: ({
						maxDate,
						minDate
					}) => {
						this.minDate = minDate
						this.maxDate = maxDate

						this.selectDate = minDate.getTime()
						if (maxDate) {
							this.selectDate = ''
						}
					},
					disabledDate: (time) => {
						// 只能选择今天及今天以前的日期
						if (this.selectDate !== '') {
							const one = 30 * 24 * 3600 * 1000
							const minTime = this.selectDate - one
							const maxTime = this.selectDate + one
							return time.getTime() < minTime || time.getTime() > maxTime
						} else {
							return time.getTime() > Date.now() - 8.64e6
						}
					},
				},
				pickerOptions_Month: {
					// 只能选择当前月及以前的月份
					disabledDate: (time) => {
						let t = new Date().getDate()
						return time.getTime() > Date.now() - 8.64e6 * t // 8.64e7 // 不包括当前月
					},
				},
				showMonthDataDia: false,
				reqDataMonth: {
					month: '',
					banzubumen: '',
				},
			}
		},
		mounted() {
			this.getStatistics()

			let today = this.$dayjs().format('YYYY-MM-DD')
			this.s_logDate = [today, today]
			this.searchLog()

			//this.loadEquipments();

			this.loadGradeGroups()

			this.$erd.listenTo(this.$refs.appContainer, (element) => {
				this.tbHeight = 100;
				this.$nextTick(() => {
					this.tbHeight = this.$refs.tbContainer.offsetHeight;
				})
			})
		},
		methods: {
			getOssImgThumb(url, h, w) {
				if (!url) {
					return ''
				}
				if (!h) h = 32
				if (!w) w = 32
				let suffix = '?x-oss-process=image/resize,m_fixed,h_' + h + ',w_' + w
				if (url.indexOf('http://') > -1 || url.indexOf('https://') > -1) {
					return url + suffix
				} else {
					return ''
				}
			},
			getStatistics() {
				this.$http.post('/BasicData/Attendance/GetStatistics').then((res) => {
					if (res.data.errcode == 0) {
						this.statistics = res.data.result
					}
				})
			},
			loadGradeGroups() {
				this.$http.post('/BasicData/Worker/GetGradeGroups').then((res) => {
					if (res.data.errcode == 0) {
						this.banzubumens = res.data.result
					}
				})
			},
			//loadEquipments() {
			//    this.$http.post('/BasicData/Equipment/ListAll').then(res => {
			//        if (res.data.errcode == 0) {
			//            this.equipments = res.data.result;
			//        }
			//    })
			//},
			changeLogPageSize(val) {
				this.reqData.limit = val
				this.reloadLogData()
			},
			changeLogCurrentPage(val) {
				this.reqData.page = val
				this.loadLogData()
			},
			reloadLogData() {
				this.tbLogs = []
				this.reqData.page = 1
				this.loadLogData()
			},
			loadLogData() {
				this.loadingLogData = true
				this.$http
					.post('/BasicData/Attendance/WorkAttendanceList', this.reqData)
					.then((res) => {
						this.loadingLogData = false
						if (res.data.errcode == 0) {
							this.totalLogCount = res.data.result.count
							this.tbLogs = res.data.result.list
						}
					})
			},
			searchLog() {
				if (this.s_logDate) {
					this.reqData.startDate = this.s_logDate[0] + ' 00:00:00'
					this.reqData.endDate = this.s_logDate[1] + ' 23:59:59'
				} else {
					this.$message({
						message: '请选择时间范围！',
						type: 'error',
					})
					return
				}
				this.reloadLogData()
			},
			resetSearch() {
				this.s_logDate = ''
				this.reqData.startDate = ''
				this.reqData.endDate = ''
				this.reqData.deviceSn = ''
				this.reqData.username = ''
				this.reqData.banzubumen = ''
			},
			exportData() {
				if (this.s_logDate) {
					this.reqData.startDate = this.s_logDate[0]
					this.reqData.endDate = this.s_logDate[1]
				} else {
					this.$message({
						message: '请选择时间范围！',
						type: 'error',
					})
					return
				}

				let loading = this.$loading()
				this.$http
					.post('/BasicData/Attendance/ExportData', this.reqData)
					.then((res) => {
						loading.close()
						if (res.data.errcode == 0) {
							this.$message({
								message: '导出成功！',
								type: 'success',
								duration: 500,
								onClose: function(e) {
									let a = document.createElement('a')
									a.href = res.data.result;
									a.click();
								},
							})
						} else {
							this.$message({
								message: '导出失败！',
								type: 'error',
							})
						}
					})
			},
			closeMonthDataDia() {
				this.reqDataMonth.month = ''
				this.reqDataMonth.banzubumen = ''
				this.$refs.formMonthData.resetFields()
			},
			confirmExportMonthData() {
				this.$refs.formMonthData.validate((valid) => {
					if (valid) {
						let loading = this.$loading()
						this.$http
							.post('/BasicData/Attendance/ExportMonthData', this.reqDataMonth)
							.then((res) => {
								loading.close()
								if (res.data.errcode == 0) {
									this.showMonthDataDia = false
									this.$message({
										message: '导出成功！',
										type: 'success',
										duration: 500,
										onClose: function(e) {
											let a = document.createElement('a')
											a.href = res.data.result;
											a.click();
										},
									})
								} else {
									this.$message({
										message: '导出失败！',
										type: 'error',
									})
								}
							})
					} else {
						return false
					}
				})
			},
		},
	}
</script>

<style lang="less" scoped>
	.app-container {
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	.container {
		flex: 1;
		display: flex;
		flex-direction: column;
		/*height: 100%;*/
		padding: 18px 8px 8px;

		.center-content {
			flex: 1;
		}
	}

	// .center-content {
	//     flex: 1;

	//     .el-scrollbar__wrap {
	//         overflow-x: hidden;
	//     }
	// }

	.user-statistics {
		display: flex;
		align-items: center;
		font-size: 16px;
		margin-bottom: 20px;

		.count {
			color: #f56c6c;
			font-weight: bold;
		}

		.user-total {}

		.user-today {
			margin-left: 20px;
		}
	}
</style>
