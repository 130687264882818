import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import Home from '../components/Home.vue'

import PhotoCapture from '../components/mobile/photo_capture.vue'

Vue.use(VueRouter)

const routes = [
    // 如果路径是/那么就重定向到login路径
    { path: '/', redirect: '/login' },
    { path: '/login', component: Login },
    {
        path: '/home',
        component: Home
    },
	{
		path: '/mobile',
		component: PhotoCapture
	}
]

const router = new VueRouter({
		mode: 'history', // 去掉url中的#
        routes
    })
    // 挂载路由导航守卫
router.beforeEach((to, from, next) => {
    if (to.path === '/login') return next()
	
	if(to.path === '/mobile') return next()
	else{
		const tokenStr = window.sessionStorage.getItem('token')
		if (!tokenStr) return next('/login')
		next()
	}
})

router.afterEach(( to, from, next ) => {
    // setTimeout(()=>{
    //       // 此处粘贴百度统计复制过来的代码
    //       var _hmt = _hmt || [];
    //       (function() {
    //         var hm = document.createElement("script");
    //         hm.src = "https://hm.baidu.com/hm.js?45872a9fb57a4863e677ad05f9628f6f";
    //         var s = document.getElementsByTagName("script")[0]; 
    //         s.parentNode.insertBefore(hm, s);
    //     })();
    // },0);
});

export default router