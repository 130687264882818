import Vue from 'vue'
// import {
//     Button,
//     Form,
//     FormItem,
//     Input,
//     Message,
//     Container,
//     Header,
//     Aside,
//     Main,
//     Menu,
//     Submenu,
//     MenuItem,
//     Breadcrumb,
//     BreadcrumbItem,
//     Card,
//     Row,
//     Col,
//     Table,
//     TableColumn,
//     Switch,
//     Tooltip,
//     Pagination,
//     Dialog,
//     MessageBox,
//     Tag,
//     Tree,
//     Select,
//     Option,
//     Cascader,
//     Alert,
//     Tabs,
//     TabPane,
//     Steps,
//     Step,
//     CheckboxGroup,
//     Checkbox,
//     Upload,
// 	ScrollBar,
// 	Divider
// } from 'element-ui'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 引入timeline组件(由于当前下载的elementui版本还没有接入timeline)
import Timeline from './timeline/index.js'
import ElTimelineItem from './timeline-item/index.js'


// Vue.use(Button)
// Vue.use(Form)
// Vue.use(FormItem)
// Vue.use(Input)
    // Message与众不同的是需要全局挂载
// Vue.prototype.$message = Message;
// Vue.use(Container)
// Vue.use(Container)
// Vue.use(Header)
// Vue.use(Aside)
// Vue.use(Main)
// Vue.use(Menu)
// Vue.use(Submenu)
// Vue.use(MenuItem)
// Vue.use(Breadcrumb)
// Vue.use(BreadcrumbItem)
// Vue.use(Card)
// Vue.use(Col)
// Vue.use(Row)
// Vue.use(Table)
// Vue.use(TableColumn)
// Vue.use(Switch)
// Vue.use(Tooltip)
// Vue.use(Pagination)
// Vue.use(Dialog)
    // 全局挂载MessageBox的confim函数
// Vue.prototype.$confirm = MessageBox.confirm
// Vue.use(Tag)
// Vue.use(Tree)
// Vue.use(Select)
// Vue.use(Option)
// Vue.use(Cascader)
// Vue.use(Alert)
// Vue.use(Tabs)
// Vue.use(TabPane)
// Vue.use(Steps)
// Vue.use(Step)
// Vue.use(CheckboxGroup)
// Vue.use(Checkbox)
// Vue.use(Upload)
// Vue.use(ScrollBar)
// Vue.use(Divider)

Vue.use(ElementUI);

Vue.use(Timeline)
Vue.use(ElTimelineItem)