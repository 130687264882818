<template>
	<el-container class="home-container">
		<!-- 头部 -->
		<el-header>
			<div class="header">
				<div class="header-left">
					<div class="logo">
						<el-avatar :src="require('../assets/logo.png')">
						</el-avatar>
						<span v-show="!isCollapse" class="logo-title">官湖中学考勤系统</span>
					</div>
					<el-divider direction="vertical"></el-divider>
					<span @click="toggleCollapse" class="collapse-operate">
						<i v-show="!isCollapse" class="el-icon-s-fold"></i>
						<i v-show="isCollapse" class="el-icon-s-unfold"></i>
					</span>
				</div>
				<div class="header-center">
				</div>
				<div class="header-right">
					<el-dropdown @command="commandDropdownHeader">
						<span class="user-area">
							<span class="header-img"><i class="el-icon-s-custom"></i></span>
							<span class="user-role">{{loginUser.Name}}</span>
						</span>
						<el-dropdown-menu class="dropdown-user" slot="dropdown">
							<el-dropdown-item command="password">修改密码</el-dropdown-item>
							<el-dropdown-item command="logout" divided>退出</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>
		</el-header>
		<el-container class="main-container">
			<!-- 侧边 -->
			<el-aside class="left-side" :width="isCollapse ? '65px' : '260px'">
				<el-scrollbar style="height:100%;">
					<!-- 给侧边栏加上按钮实现菜单栏的展开/收起 -->
					<!-- 侧边菜单栏 -->
					<el-menu ref="leftMenu" class="" :default-active="activeSubMenu + ''" :collapse="isCollapse"
						:collapse-transition="false">
						<template v-for="(menu, idxm) in menuList">
							<el-submenu :class="{'is-active':menu.id==activeSubMenu}"
								v-if="menu.children && menu.children.length > 0" :key="idxm" :index="menu.id + ''">
								<template slot="title">
									<i :class="menu.icon"></i>
									<span>{{menu.name}}</span>
								</template>
								<template v-for="(subMenu, idxsm) in menu.children">
									<el-submenu :class="{'is-active':subMenu.id==activeSubMenu}"
										v-if="subMenu.children && subMenu.children.length > 0" :key="idxsm"
										:index="subMenu.id + ''">
										<template slot="title">
											<i :class="subMenu.icon"></i>
											<span>{{subMenu.name}}</span>
										</template>
										<el-menu-item v-for="(ssubMenu, idxssm) in subMenu.children" :key="idxssm"
											:class="{'is-active':ssubMenu.id==activeSubMenu}" :index="ssubMenu.id + ''"
											@click="subMenuClick(ssubMenu)">
											<i :class="ssubMenu.icon"></i>
											<span slot="title">{{ssubMenu.name}}</span>
										</el-menu-item>
									</el-submenu>
									<el-menu-item :class="{'is-active':subMenu.id==activeSubMenu}" v-else :key="idxsm"
										:index="subMenu.id + ''" @click="subMenuClick(subMenu)">
										<i :class="subMenu.icon"></i>
										<span slot="title">{{subMenu.name}}</span>
									</el-menu-item>
								</template>
							</el-submenu>
							<el-menu-item :class="{'is-active':menu.id==activeSubMenu}" v-else :key="idxm"
								:index="menu.id + ''" @click="subMenuClick(menu)">
								<i :class="menu.icon"></i>
								<span slot="title">{{menu.name}}</span>
							</el-menu-item>
						</template>
					</el-menu>
				</el-scrollbar>
			</el-aside>
			<!-- 主体 -->
			<el-main class="center">
				<!-- <router-view v-on:subMenuClick="subMenuClick"></router-view> -->

				<div class="tab-operate">
					<el-dropdown @command="commandDropdownMenu">
						<span class="el-dropdown-link">
							<i class="el-icon-s-operation" style="font-size: 18px; "></i>
							<i class="el-icon-arrow-down"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="current" :disabled="!canRemoveTab">关闭标签页</el-dropdown-item>
							<el-dropdown-item command="other" :disabled="!canRemoveTab">关闭其他标签页</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
				<el-tabs class="main-tabs" v-model="activeTab" type="card" @tab-click="clickTab" @tab-remove="closeTab">
					<el-tab-pane v-for="(item, index) in tabs" v-if="item.tab.isdel == 0" :key="index"
						:label="item.tab.title" :name="item.tab.id + ''" :closable="canRemoveTab">
						<!-- <component :is="item.tab.path" v-on:subMenuClick="subMenuClick"></component> -->
					</el-tab-pane>
				</el-tabs>
				
				<div class="tab-container">
					<div class="tab-item" v-for="(item, index) in tabs" v-if="item.tab.isdel == 0" :key="index" v-show="activeTab == item.tab.id">
						<component :is="item.tab.path" v-on:subMenuClick="subMenuClick" ></component>
					</div>
				</div>
			</el-main>
		</el-container>
		<el-footer class="custom-footer" height="33px">
			<div class="xm-bottom">
				<div class="bottom-link-button">Copyright © 技术支持：徐州讯铭物联科技有限公司</div>
			</div>
		</el-footer>

		<el-dialog title="修改密码" :visible.sync="showUpdatePswdDia" :close-on-click-modal="false" width="500px">
			<div>
				<el-form :model="formUser" :rules="rules" ref="formUser" label-width="90px">
					<el-form-item prop="password" label="原密码">
						<el-input v-model="formUser.password" show-password size="large" :disabled="loadingUpdatePswd"
							placeholder="请输入原密码"></el-input>
					</el-form-item>
					<el-form-item prop="newPassword" label="新密码">
						<el-input v-model="formUser.newPassword" show-password size="large"
							:disabled="loadingUpdatePswd" placeholder="请输入新密码"></el-input>
					</el-form-item>
					<el-form-item prop="okPassword" label="确认密码">
						<el-input v-model="formUser.okPassword" show-password size="large" :disabled="loadingUpdatePswd"
							placeholder="请输入确认密码"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancelUpdatePassword">取 消</el-button>
				<el-button type="primary" :loading="loadingUpdatePswd" @click="confirmUpdatePassword">确 定</el-button>
			</span>
		</el-dialog>

	</el-container>
</template>

<script>
	export default {
		name: 'home',
		created() {
			//  组件被创建后调用函数获取菜单
			this.getMenuList()
			// home页面一刷新会重新解析渲染页面，就从session中获取当前的路径给到activePath
			this.activePath = window.sessionStorage.getItem('acitvePath')

			this.loginUser = JSON.parse(window.sessionStorage.getItem('user'))

			// this.tabs.push({
			// 	rootMenu: '',
			// 	tab: {
			// 		id: 65,
			// 		title: '首页',
			// 		path: 'Welcome',
			// 		isdel: 0
			// 	}
			// })
			// this.activeTab = '65';
		},
		computed: {
			canRemoveTab() {
				return this.tabs.filter(t => t.tab.isdel == 0).length > 1;
			},
		},
		data() {
			return {
				menuList: [],
				mid: '',
				rmenu: '',

				clickedMenu: '',
				activeTopMenu: '',
				activeSubMenu: '',

				isCollapse: false,

				tabs: [],
				activeTab: '0',

				showUpdatePswdDia: false,
				loadingUpdatePswd: false,
				formUser: {
					password: '',
					newPassword: '',
					okPassword: ''
				},
				rules: {
					password: [{
						required: true,
						message: '请输入原密码',
						trigger: 'blur'
					}],
					newPassword: [{
							required: true,
							message: '请输入新密码',
							trigger: 'blur'
						},
						{
							validator: (rule, value, callback) => {
								if (this.formUser.okPassword !== '') {
									this.$refs.formUser.validateField('okPassword');
								}
								callback();
							},
							trigger: 'blur'
						}
					],
					okPassword: [{
							required: true,
							message: '请输入确认密码',
							trigger: 'blur'
						},
						{
							validator: (rule, value, callback) => {
								if (value !== this.formUser.newPassword) {
									callback(new Error('两次输入密码不一致!'));
								} else {
									callback();
								}
							},
							trigger: 'blur'
						}
					],
				},

				loginUser: '',
			}
		},
		methods: {
			logout() {
				// 退出两步：清空session、重定向到登录页
				window.sessionStorage.clear()
				this.$router.push('/login')
			},
			// 获取所有菜单
			async getMenuList() {
				const {
					data: res
				} = await this.$http.post('/Home/GetNavMenus')
				// 如果请求不成功，提示错误信息——服务器的信息反馈在data.meta的msg中
				if (res.errcode !== 0) return this.$message.error(res.errmsg)
				// 否则请求成功则将获取到的菜单数据给到组件data中定义的数组
				this.menuList = JSON.parse(res.result)
				
				this.topMenuClick(this.menuList[0]);
			},
			// 点击按钮切换菜单的折叠与展开
			toggleCollapse() {
				this.isCollapse = !this.isCollapse
			},
			// 保存链接的激活状态——保存住当前的页面对应的菜单栏条目，以备在页面刷新后条目依旧高亮
			saveRoutePath(activePath) {
				window.sessionStorage.setItem('acitvePath', activePath)
				// !!!
				this.activePath = activePath
			},
			getMenuById(menus) {
				for (let i = 0, len = menus.length; i < len; i++) {
					let smenus = menus[i].children;
					if (smenus) {
						let m = smenus.find(m => m.id == this.mid);
						if (m) {
							this.rmenu = m;
							break;
						} else {
							this.getMenuById(smenus);
							if (this.rmenu) {
								break;
							}
						}
					} else {
						continue;
					}
				}
			},
			topMenuClick(menu, smenu) {
				this.clickedMenu = menu;
				this.activeTopMenu = menu.id;

				if (smenu) {
					this.subMenuClick(smenu);
				} else if (this.clickedMenu.children && this.clickedMenu.children.length > 0) {
					let tmpMenu = this.getLeafMenu(this.clickedMenu.children[0]);
					this.subMenuClick(tmpMenu);
				}
			},
			subMenuClick(menu) {
				let id = menu.id;
				let tab = this.tabs.find(t => t.tab && t.tab.isdel == 0 && t.tab.id == id);

				if (!tab) {
					let url = '/';
					if (menu.nameSpace) {
						url += menu.nameSpace + '/';
					}
					url += menu.controller + '/' + menu.action;
					this.tabs.push({
						rootMenu: this.clickedMenu,
						tab: {
							id: id,
							title: menu.name,
							path: menu.code,
							url: url,
							isdel: 0
						}
					})

					for (let i = this.tabs.length - 1; i >= 0; i--) {
						if (this.tabs[i].tab.isdel == 1) {
							this.tabs.splice(i, 1);
						}
					}
				}
				this.activeTab = id + '';
				this.activeSubMenu = id;
			},
			getLeafMenu(menu) {
				if (menu.children && menu.children.length > 0) {
					menu = this.getLeafMenu(menu.children[0]);
				}

				return menu;
			},
			clickTab(tab) {
				//let tmp = this.tabs[tab.index];
				let tmp = this.tabs.find(t => t.tab.isdel == 0 && t.tab.id == tab.name);
				if (tmp) {
					if (tmp.rootMenu.id != this.clickedMenu.id) {
						this.clickedMenu = tmp.rootMenu;
						this.activeTopMenu = tmp.rootMenu.id;
					}
					this.activeTab = tmp.tab.id + '';
					this.activeSubMenu = tmp.tab.id;
				} else
					this.activeSubMenu = '';
			},
			closeTab(tabName) {
				let idx = this.tabs.findIndex(t => t.tab && t.tab.isdel == 0 && t.tab.id == tabName);
				// this.tabs.splice(idx, 1);
				if (idx > -1) {
					this.tabs[idx].tab.isdel = 1;

					if (this.activeTab == tabName) {
						let tmp = this.tabs.find((t, index, arr) => {
							return index > idx && t.tab.isdel == 0;
						})
						if (!tmp) {
							for (let i = idx - 1; i >= 0; i--) {
								if (this.tabs[i].tab.isdel == 0) {
									tmp = this.tabs[i];
									break;
								}
							}
						}

						if (tmp.rootMenu.id != this.clickedMenu.id) {
							this.clickedMenu = tmp.rootMenu;
							this.activeTopMenu = tmp.rootMenu.id;
						}

						this.activeTab = tmp.tab.id + '';
						this.activeSubMenu = tmp.tab.id;
					}
				}
			},
			commandDropdownMenu(command) {
				switch (command) {
					case 'current': {
						if (this.tabs.length > 1) {
							let idx = this.tabs.findIndex(t => t.tab.isdel == 0 && t.tab.id == this.activeTab);
							if (idx > -1) {
								// this.tabs.splice(idx, 1);
								this.tabs[idx].tab.isdel = 1;

								let tmp = this.tabs.find((t, index, arr) => {
									return index > idx && t.tab.isdel == 0;
								})
								if (!tmp) {
									for (let i = idx - 1; i >= 0; i--) {
										if (this.tabs[i].tab.isdel == 0) {
											tmp = this.tabs[i];
											break;
										}
									}
								}

								if (tmp.rootMenu.id != this.clickedMenu.id) {
									this.clickedMenu = tmp.rootMenu;
									this.activeTopMenu = tmp.rootMenu.id;
								}

								this.activeTab = tmp.tab.id + '';
								this.activeSubMenu = tmp.tab.id;
							}
						}
						break;
					}
					case 'other': {
						for (let i = this.tabs.length - 1; i >= 0; i--) {
							if (this.tabs[i].tab.id != this.activeTab) {
								// this.tabs.splice(i, 1);
								this.tabs[i].tab.isdel = 1;
							}
						}
						break;
					}
					case 'all': {
						this.tabs = [];
						this.activeTab = '0';
						this.activeSubMenu = '';
						this.activeTopMenu = '';
						break;
					}
				}
			},
			commandDropdownHeader(command) {
				switch (command) {
					case 'personal': { // 个人资料
						break;
					}
					case 'password': { // 修改密码
						this.formUser = {
							password: '',
							newPassword: '',
							okPassword: ''
						};
						this.showUpdatePswdDia = true;
						this.$nextTick(() => {
							this.$refs.formUser.resetFields();
						})
						break;
					}
					case 'logout': { // 退出
						this.logout()
						break;
					}
				}
			},
			cancelUpdatePassword() { // 取消，关闭对话框
				this.$refs.formUser.resetFields();
				this.showUpdatePswdDia = false;
			},
			confirmUpdatePassword() {
				this.$refs.formUser.validate((valid) => {
					if (valid) {
						let that = this;
						this.loadingUpdatePswd = true;
						this.$http.post('/Home/UpdatePassword', this.formUser).then(res => {
							if (res.data.errcode == 0) {
								this.$message({
									message: '修改成功！',
									type: 'success',
									duration: 1000,
									onClose: function(e) {
										that.showUpdatePswdDia = false;
										that.loadingUpdatePswd = false;
									}
								});
							} else {
								this.loadingUpdatePswd = false;
								this.$message({
									message: res.data.errmsg,
									type: 'error',
									duration: 1000
								})
							}
						})
					} else {
						return false;
					}
				});
			}
		},
	}
</script>

<style lang="less" scoped>
	.home-container {
		height: 100%;
	}

	// 头部采用flex布局
	.el-header {
		color: #fff;
		background-color: #409eff;
		// background-color: rgba(219, 238, 240, 0.651);
		display: flex;
		justify-content: space-between;
		padding: 0 20px;
		//  让按钮上下居中（垂直居中）而不是上下贴边
		align-items: center;
		font-size: 20px;

		.header-container {
			display: flex;
			align-items: center;

			img {
				width: 70px;
			}

			span {
				margin-left: 15px;
			}
		}
	}

	.el-aside {

		.el-menu {
			border-right: none !important;
		}
	}

	.el-main {}

	.iconfont {
		// 图标和文本之间有点间距
		margin-right: 10px;
	}

	.toggle-button {
		background-color: rgb(210, 241, 243);
		font-size: 10px;
		line-height: 24px;
		color: rgb(168, 149, 162);
		text-align: center;
		cursor: pointer;
		// 让字符之间有一定的0.2em的距离
		letter-spacing: 0.2em;
	}

	.main-container {
		overflow: hidden;

		::v-deep .el-scrollbar__bar.is-horizontal {
			display: none;
		}
	}

	.header {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		user-select: none;

		.header-left {
			display: flex;
			align-items: center;

			.logo {
				display: flex;
				align-items: center;

				.logo-title {
					font-size: 20px;
					margin-left: 8px;
					font-weight: bold;
				}
			}

			.collapse-operate {
				font-size: 20px;
				cursor: pointer;
			}
		}

		.header-center {
			flex: 1;
			display: flex;
			align-items: center;
			padding: 0 50px;

			.top-menu {
				background-color: inherit;
				border-bottom: 0;

				.menu-item {
					color: #fff;
					border-bottom: 0;

					&:hover {
						background-color: rgba(125, 189, 255, 0.5);
						/*background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);*/
					}

					&.is-active {
						border-bottom: 0;
						background-color: rgba(125, 189, 255, 0.5);
					}
				}
			}
		}

		.header-right {
			.user-area {
				display: flex;
				align-items: center;
				color: #fff;
				cursor: pointer;

				.header-img {
					border-radius: 50%;
					padding: 6px;
					background: #007cd4;
					display: flex;
					font-size: 18px;
				}

				.user-role {
					margin-left: 5px;
				}
			}
		}
	}

	.left-side {
		position: relative;
		overflow: hidden;
		user-select: none;

		&::after {
			content: '';
			position: absolute;
			width: 1px;
			top: 0;
			bottom: 0;
			right: 0;
			background-color: #e6e6e6;
		}

		::v-deep .el-scrollbar__wrap {
			overflow-x: hidden;
		}
	}

	.center {
		position: relative;
		padding: 10px 20px;
		overflow: hidden;

		display: flex;
		flex-direction: column;


		.tab-operate {
			position: absolute;
			right: 20px;
			top: 20px;
			z-index: 999;
			cursor: pointer;
		}
	}

	.custom-footer {
		border-top: 1px solid #eeeeee;

		.xm-bottom {
			display: flex;
			align-items: center;
			justify-content: center;
			position: fixed;
			left: 0;
			right: 0;
			bottom: 10px;

			.bottom-link-button {
				/*padding: 0 15px;*/
				line-height: 1;
				position: relative;
				color: #868686;
				font-size: 13px;

				&:not(:last-child) {
					padding-right: 6px;
					margin-right: 6px;

					&::after {
						content: '';
						position: absolute;
						top: 0;
						bottom: 0;
						right: 0;
						background-color: #999;
						width: 1.5px;
					}
				}

				a {
					color: inherit;
					text-decoration: none;

					&:hover {
						color: #fff;
					}
				}
			}
		}
	}

	.main-tabs {
		width: 100%;
		// height: 100%;
		// display: flex;
		// flex-direction: column;

		::v-deep .el-tabs__header {
			margin: 0;
			padding-right: 50px;
		}

		// ::v-deep .el-tabs__content {
		// 	flex: 1;

		// 	.el-tab-pane {
		// 		height: 100%;
		// 	}
		// }
	}
	
	.tab-container{
		flex: 1;
		overflow: hidden;
		
		.tab-item{
			height: 100%;
		}
	}
</style>
