import Vue from 'vue'
import App from './App.vue'
import router from './router'

import components from './components/components.js'

import './plugins/element.js'
// 导入全局样式表
import './assets/css/global.css'
// 导入字体图标
import './assets/fonts/iconfont.css'
// 导入发送请求的包
import axios from 'axios'
// 导入vue-table-with-tree-grid插件---用于商品分类中的树形表格展示功能
import TreeTable from 'vue-table-with-tree-grid'
// 导入富文本编辑器及其依赖的样式
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
// 导入nprogress进度条的JS和CSS
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import ElementResizeDetectorMaker from "element-resize-detector"
Vue.prototype.$erd = ElementResizeDetectorMaker()

import dayjs from 'dayjs'

import html2canvas from 'html2canvas';

import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

Vue.prototype.$dayjs = dayjs;

Vue.prototype.html2canvas = html2canvas;

// 将axios包挂载到Vue的原型对象上，每一个Vue组件都可以通过this访问到http去发起Ajax请求
Vue.prototype.$http = axios;
// 配置请求的根路径
axios.defaults.baseURL = 'http://newatt.zhujianiot.com/api'; // 'http://127.0.0.1:8888/api/private/v1/';
// 设置请求拦截
axios.interceptors.request.use(config => {
        // 在request拦截器中展示进度条
        NProgress.start()

        // console.log(config);
        // 为config做处理，根据API文档中所写的要添加一个属性Authorization，属性值就是token
        config.headers.Authorization = window.sessionStorage.getItem('token');
        // 最后必须将config返回，固定写法
        return config
    })
    // 在response拦截器中隐藏进度条
let messageboxInstans = null;
axios.interceptors.response.use(config => {
    // 响应成功了就隐藏进度条
    NProgress.done()
	
	if(config.data.errcode == -1){
		if(!messageboxInstans){
			messageboxInstans = Vue.prototype.$alert('登录状态已过期，请重新登录', '系统提示', {
			  type: 'warning'
			}).then(() => {
				// 清空session、重定向到登录页
				window.sessionStorage.clear()
				router.replace({path: 'login'})
				messageboxInstans = null
			})
		}
	}
	
    return config
})
Vue.config.productionTip = false
    // 将这个插件注册为全局组件
Vue.component('tree-table', TreeTable)

Vue.use(components)
    // 将富文本编辑器注册为全局可用的组件
Vue.use(VueQuillEditor)
    // 注册全局过滤器---时间过滤器---根据时间戳来按需要的格式返回时间
Vue.filter('dateFormat', function(originVal) {
        const dt = new Date(originVal * 1000)
        const y = dt.getFullYear()
            // 月份是从0开始的，所以得+1（padStart希望数据是2位的，不足两位则第一位用0替代）
        const m = (dt.getMonth() + 1 + '').padStart(2, '0')
        const d = (dt.getDate() + '').padStart(2, '0')
        const hh = (dt.getHours() + '').padStart(2, '0')
        const mm = (dt.getMinutes() + '').padStart(2, '0')
        const ss = (dt.getSeconds() + '').padStart(2, '0')
            // return `yyyy-mm-dd hh:mm:ss`
        return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
    })
    // 给Vue原型上加一个数据用于存储商品id;编辑时跳转到其他页面对数据进行查询时使用
Vue.prototype.editGoodsID = { id: 0 }

/**
 * 身份证号码校验
 * @param {String} idcard
 * @return {Boolean}
 */
Vue.prototype.$validateIDCard = function(idcard){
	let result = true;
	var Wi = [ 7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2, 1 ];// 加权因子
	var ValideCode = [ 1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2 ];// 身份证验证位值.10代表X   
	
	let idCards = idcard.replace(/ /g, "");
	if(idCards !=null && idCards !="" && idCards.charAt(idCards.length - 1)=="x")
	{
		result = false;
		return result;
	}
	
	if (idCards.length == 15) {
		var year =  idCards.substring(6,8);   
		var month = idCards.substring(8,10);   
		var day = idCards.substring(10,12);   
		var temp_date = new Date(year,parseFloat(month)-1,parseFloat(day));   
		if(temp_date.getYear()!=parseFloat(year)   
				||temp_date.getMonth()!=parseFloat(month)-1   
				||temp_date.getDate()!=parseFloat(day)){   
					result = false;
		  }   
	} else if (idCards.length == 18) {   
		var a_idCard = idCards.split("");// 得到身份证数组   
		var year =  idCards.substring(6,10);   
		var month = idCards.substring(10,12);   
		var day = idCards.substring(12,14);   
		var temp_date = new Date(year,parseFloat(month)-1,parseFloat(day));   
		if(temp_date.getFullYear()!=parseFloat(year)   
			  ||temp_date.getMonth()!=parseFloat(month)-1   
			  ||temp_date.getDate()!=parseFloat(day)){   
				  result = false;
				 return result;
		} 
		
		var sum = 0; // 声明加权求和变量   
		if (a_idCard[17].toLowerCase() == 'x') {   
			a_idCard[17] = 10;// 将最后位为x的验证码替换为10方便后续操作   
		}   
		for ( var i = 0; i < 17; i++) {   
			sum += Wi[i] * a_idCard[i];// 加权求和   
		}   
		let valCodePosition = sum % 11;// 得到验证码所位置   
		if (a_idCard[17] != ValideCode[valCodePosition]) {   
			result = false;
		} 
	} else {   
		result = false;
	}
	
	return result;
}

// 判断arr是否为一个数组，返回一个bool值
Vue.prototype.isArray = function(arr) {
    return Object.prototype.toString.call(arr) === '[object Array]';
}

// 深度克隆
Vue.prototype.deepClone = function(obj) {
    // 对常见的“非”值，直接返回原来值
    if ([null, undefined, NaN, false].includes(obj)) return obj;
    if (typeof obj !== "object" && typeof obj !== 'function') {
        //原始类型直接返回
        return obj;
    }
    var o = this.isArray(obj) ? [] : {};
    for (let i in obj) {
        if (obj.hasOwnProperty(i)) {
            o[i] = typeof obj[i] === "object" ? this.deepClone(obj[i]) : obj[i];
        }
    }
    return o;
}

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')