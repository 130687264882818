
import Attendance from './basic/Attendance.vue'
import Equipment from './basic/Equipment.vue'
import Department from './basic/Department.vue'
import Worker from './basic/Worker.vue'
import FaceConfig from './basic/FaceConfig.vue'
import Arrange from './basic/Arrange.vue'
import ArrangePerson from './basic/Arrange_Person.vue'

import MenuManage from './system/menu.vue'
import RoleManage from './system/role.vue'
import UserManage from './system/user.vue'

export default {
	install(Vue){
		Vue.component('Attendance', Attendance) // 智能管理 - 首页
		Vue.component('Equipment', Equipment) // 智能管理 - 设备管理
		Vue.component('Department', Department) // 智能管理 - 部门管理
		Vue.component('Worker', Worker) // 智能管理 - 人员管理
		Vue.component('FaceConfig', FaceConfig) // 智能管理 - 人脸评分标准
		Vue.component('Arrange', Arrange) // 班次设置
		Vue.component('ArrangePerson', ArrangePerson) // 人员排班
		
		Vue.component('MenuManage', MenuManage) // 菜单管理
		Vue.component('RoleManage', RoleManage) // 角色管理
		Vue.component('UserManage', UserManage) // 用户管理
	}
}