<!-- 智能管理 - 设备管理 -->
<template>
	<div class="app-container" ref="appContainer">
		<el-container>
			<el-aside class="left-side" width="260px">
				<el-scrollbar style="height:100%;">
					<div class="equipment-group-title">设备分组
						<!-- <el-button type="primary" size="mini" @click="addGroup"><i class="el-icon-plus"></i>添加分组</el-button> -->
					</div>
					<el-tree class="tree-equipment-group" ref="treeEquipmentGroup" :data="equipmentGroups"
						:props="defaultProps" :highlight-current="true" :default-expand-all="true" node-key="groupId"
						@node-click="changeGroup">
						<span class="custom-tree-node" slot-scope="{ node, data }">
							<i class="el-icon-copy-document"></i>
							<span>{{ node.label }}</span>
						</span>
					</el-tree>
				</el-scrollbar>
			</el-aside>
			<el-main class="center">
				<div class="container">
					<div class="top-bar">
						<el-button type="primary" @click="reloadDevices"><i class="el-icon-refresh"></i>刷新</el-button>
						<el-button type="primary" @click="resetDevices"><i class="el-icon-refresh"></i>重置</el-button>
						<!-- <el-button type="primary" @click="addDevice"><i class="el-icon-plus"></i>添加设备</el-button> -->
						<!-- <el-button type="primary" @click="bindPerson"><i class="el-icon-link"></i>人员绑定</el-button> -->
					</div>
					<div class="center-content" ref="tbContainer">
						<!-- 下面放表格 -->
						<el-table v-loading="loadingProject" ref="tbDevices" :data="tbDevices" border stripe
							:height="tbHeight" :header-cell-style="{textAlign: 'center'}"
							:cell-style="{ textAlign: 'center' }" tooltip-effect="dark" style="width: 100%">
							<el-table-column type="index" label="序号" width="80">
							</el-table-column>
							<el-table-column prop="install_address" label="安装位置" width="300">
							</el-table-column>
							<el-table-column prop="deviceSn" label="设备编码(SN)" width="200">
							</el-table-column>
							<el-table-column prop="groupName" label="所属分组" width="200"></el-table-column>
							<el-table-column prop="state" label="所属权限分组" width="200">
								<template slot-scope="scope">
									<span v-if="scope.row.permissionGroupId">{{scope.row.permissionGroupName}}</span>
									<el-button v-else size="small" type="primary" @click="bindPermissionGroup(scope.row)">绑定权限分组</el-button>
								</template>
							</el-table-column>
							<el-table-column prop="state" label="状态" width="100">
								<template slot-scope="scope">
									<el-tag type="success" effect="dark" v-if="scope.row.state == 1">在线</el-tag>
									<el-tag type="info" effect="dark" v-else>离线</el-tag>
								</template>
							</el-table-column>
							<!-- <el-table-column label="操作" width="300">
								<template slot-scope="scope">
									<el-button size="small" type="danger" :disabled="scope.row.state==0"
										@click="restart(scope.row)">设备重启</el-button>
									<el-button size="small" type="primary" @click="bindPerson(scope.row)"><i
											class="el-icon-link"></i>人员绑定</el-button>
								</template>
							</el-table-column> -->
						</el-table>
					</div>
					<!-- 这里放置分页组件 -->
					<el-pagination class="page-bar" @size-change="changePageSize" @current-change="changeCurrentPage"
						:current-page="reqData.page" :page-size="reqData.limit" :total="totalCount"
						:page-sizes="[10, 20, 30, 40, 50]" layout="total, sizes, prev, pager, next">
					</el-pagination>
				</div>
			</el-main>
		</el-container>


		<!-- 这里放置弹窗区域 -->
		<el-dialog :title="bindPersonDiaTitle" :visible.sync="showBindPersonDia" :close-on-click-modal="false"
			:fullscreen="true" custom-class="dia-bind-person" @close="cancelAddOrEditGroup">
			<div style="height: 100%;">
				<iframe :src="bindPersonUrl" width="100%" height="100%" frameborder="0"></iframe>
			</div>
		</el-dialog>
		
		<!-- 添加分组 -->
		<el-dialog title="添加分组" :visible.sync="showAddOrEditGroup" :close-on-click-modal="false" width="500px">
			<div>
				<el-form :model="formGroup" :rules="rulesGroup" ref="formGroup" label-width="90px">
					<el-form-item prop="groupName" label="组名称">
						<el-input v-model="formGroup.groupName" size="large" maxlength="100" placeholder="请输入组名称"></el-input>
					</el-form-item>
					<el-form-item prop="groupNo" label="组编号">
						<el-input v-model="formGroup.groupNo" size="large" maxlength="36" placeholder="请输入组编号">
						</el-input>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancelAddOrEditGroup">取 消</el-button>
				<el-button type="primary" @click="confirmAddOrEditGroup">确 定</el-button>
			</span>
		</el-dialog>
		
		<!-- 添加设备 -->
		<el-dialog title="添加设备" :visible.sync="showAddOrEdit" :close-on-click-modal="false" width="500px" @close="cancelAddOrEdit">
			<div>
				<el-form :model="formDevice" :rules="rulesDevice" ref="formDevice" label-width="100px">
					<el-form-item prop="groupName" label="所属分组">
						<el-input v-model="formDevice.groupName" size="large" disabled maxlength="100" placeholder="请输入组名称"></el-input>
					</el-form-item>
					<el-form-item prop="deviceSn" label="设备序列号">
						<el-input v-model="formDevice.deviceSn" size="large" maxlength="36" placeholder="请输入设备序列号">
						</el-input>
					</el-form-item>
					<el-form-item prop="validCode" label="验证码">
						<el-input v-model="formDevice.validCode" size="large" maxlength="36" placeholder="请输入验证码">
						</el-input>
					</el-form-item>
					<el-form-item prop="install_address" label="安装位置">
						<el-input v-model="formDevice.install_address" size="large" maxlength="100" placeholder="请输入安装位置">
						</el-input>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancelAddOrEdit">取 消</el-button>
				<el-button type="primary" @click="confirmAddOrEdit">确 定</el-button>
			</span>
		</el-dialog>
		
		<!--绑定权限分组-->
		<el-dialog title="绑定权限分组" :visible.sync="showPermissionDia" :close-on-click-modal="false" width="500px">
			<div>
				<el-select v-model="permissionGroupDevice.groupId" placeholder="请选择权限分组" style="width: 100%;">
					<el-option v-for="(item, index) in permissionGroups" :key="index" :label="item.groupName"
						:value="item.groupId">
					</el-option>
				</el-select>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancelPermission">取 消</el-button>
				<el-button type="primary" @click="confirmPermission">确 定</el-button>
			</span>
		</el-dialog>
		
	</div>
</template>
<script>
	export default {
		data() {
			return {
				equipmentGroups: [],
				defaultProps: {
					children: 'children',
					label: 'groupName'
				},
				
				showAddOrEditGroup: false,
				formGroup: {
					groupId: '',
					groupNo: '',
					groupName: ''
				},
				rulesGroup: {
					groupNo:[{
						required: true,
						message: '请输入组编号',
						trigger: 'blur'
					}],
					groupName:[{
						required: true,
						message: '请输入组名称',
						trigger: 'blur'
					}],
				},
				
				showAddOrEdit: false,
				formDevice: {
					groupId: '',
					groupNo: '',
					groupName: '',
					deviceSn: '',
					validCode: '',
					install_address: ''
				},
				rulesDevice: {
					groupNo:[{
						required: true,
						message: '请选择分组',
						trigger: 'blur'
					}],
					deviceSn:[{
						required: true,
						message: '请输入设备序列号',
						trigger: 'blur'
					}],
					validCode:[{
						required: true,
						message: '请输入验证码',
						trigger: 'blur'
					}],
					install_address:[{
						required: true,
						message: '请输入安装位置',
						trigger: 'blur'
					}],
				},

				loadingProject: true,

				reqData: {
					page: 1,
					limit: 10,
					groupId: ''
				},
				totalCount: 0,
				tbDevices: [],

				tbHeight: 300,

				showBindPersonDia: false,
				bindPersonDiaTitle: '绑定人员',
				bindPersonUrl: '',
				
				//绑定权限分组
				permissionGroupDevice:{
					deviceSerials:[],
					groupNo: '',
					groupId:''
				},
				permissionGroups:[],
				showPermissionDia: false,
			}
		},
		mounted() {
			this.getEquipmentGroups();

			this.loadDevices();
			
			//加载权限分组
			this.loadPermissionGroup();

			this.$erd.listenTo(this.$refs.appContainer, (element) => {
				this.tbHeight = 100;
				this.$nextTick(() => {
					this.tbHeight = this.$refs.tbContainer.offsetHeight;
				})
			})
		},
		methods: {
			getEquipmentGroups() {
				this.$http.post('/BasicData/Equipment/GetEquipmentGroups').then(res => {
					if (res.data.errcode == 0) {
						this.equipmentGroups = res.data.result;
					}
				})
			},
			changeGroup(data, node, e){
				this.reqData.groupId = data.groupId;
				
				this.formDevice.groupId = data.groupId;
				this.formDevice.groupNo = data.groupNo;
				this.formDevice.groupName = data.groupName;
				
				this.reloadDevices();
			},
			addGroup(){
				this.formGroup = {
					groupId: '',
					groupNo: '',
					groupName: ''
				}
				
				this.showAddOrEditGroup = true;
			},
			cancelAddOrEditGroup(){
				this.$refs.formGroup.clearValidate();
				this.showAddOrEditGroup = false;
			},
			confirmAddOrEditGroup(){
				this.$refs.formGroup.validate((valid) => {
					if(valid){
						this.$http.post('/BasicData/Equipment/AddEquipmentGroup', this.formGroup).then(res => {
							if (res.data.errcode == 0) {
								this.$message({
									message: '添加成功',
									type: 'success'
								});					
								this.cancelAddOrEditGroup();
								this.getEquipmentGroups();
							}else
							{
								this.$message({
									message: '当前账号已存在!',
									type: 'success'
								});					
								this.cancelAddOrEditGroup();
							}
						})
					} else {
						return false;
					}
				})
			},
			loadDevices() {
				this.loadingProject = true;
				this.$http.post('/BasicData/Equipment/List', this.reqData).then(res => {
					this.loadingProject = false;
					if (res.data.errcode == 0) {
						this.totalCount = res.data.result.count;
						this.tbDevices = res.data.result.list;
					}
				})
			},
			changePageSize(val) {
				this.reqData.limit = val;
				this.reloadDevices();
			},
			changeCurrentPage(val) {
				this.reqData.page = val;
				this.loadDevices();
			},
			resetDevices(){
				this.reqData.groupId = '';
				this.$refs.treeEquipmentGroup.setCurrentKey(null);
				this.reloadDevices()
			},
			reloadDevices() {
				this.tbDevices = [];
				this.reqData.page = 1;
				this.loadDevices();
			},
			restart(device) {
				this.$confirm('确定要重启该设备吗', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					closeOnClickModal: false,
				}).then(() => {
					this.$http.post('/BasicData/Equipment/Restart', {
						deviceSn: device.deviceSn
					}).then(res => {
						if (res.data.errcode == 0) {
							this.$message({
								message: res.data.errmsg,
								type: 'success'
							});
						}
					})
				}).catch(() => {

				});
			},
			bindPerson(device) {
				let title = '人员绑定',
					url = '/BasicData/Equipment/BindPerson';
				if (device && device.deviceSn) {
					title += '【' + device.install_address + '】';
					url += '?deviceSn=' + device.deviceSn;
				}

				this.bindPersonUrl = url;

				this.bindPersonDiaTitle = title;
				this.showBindPersonDia = true;
			},
			addDevice(){
				if(!this.formDevice.groupId){
					this.$message({
						message: '请先选择设备分组!',
						type: 'error'
					});	
					return;
				}
				
				this.formDevice.deviceSn = '';
				this.formDevice.validCode = '';
				this.formDevice.install_address = '';
				
				this.showAddOrEdit = true;
			},
			cancelAddOrEdit(){
				this.$refs.formDevice.clearValidate();
				this.showAddOrEdit = false;
			},
			confirmAddOrEdit(){
				this.$refs.formDevice.validate((valid) => {
					if(valid){
						this.$http.post('/BasicData/Equipment/Add', this.formDevice).then(res => {
							if (res.data.errcode == 0) {
								this.$message({
									message: '添加成功',
									type: 'success'
								});					
								this.cancelAddOrEdit();
								this.reloadDevices();//重新加载
							}else
							{
								this.$message({
									message: '当前账号已存在!',
									type: 'success'
								});					
								this.cancelAddOrEdit();
							}
						})
					} else {
						return false;
					}
				})
			},
			loadPermissionGroup(){
				this.$http.post('/BasicData/Worker/GetPermissionGroups').then(res => {
					if (res.data.errcode == 0) {
						this.permissionGroups = res.data.result
					}
				})
			},
			bindPermissionGroup(device){
				//绑定权限分组
				this.permissionGroupDevice = {
					deviceSerials:[device.deviceSn],
					groupNo: device.groupNo,
					groupId:''
				};
				this.showPermissionDia = true;
			},
			confirmPermission() { // 绑定权限分组							
				this.$http.post('/BasicData/Equipment/BindPermissionGroups', this.permissionGroupDevice).then(res => {
				   if (res.data.errcode == 0) {
				       this.$message({
				           message: '绑定权限分组成功',
				           type: 'success'
				       });
					   this.cancelPermission();
				   } else {
				       this.$message({
				           message: res.data.errmsg,
				           type: 'error'
				       });
				   }
				})
			},
			cancelPermission() { // 取消，关闭对话框
				this.permissionGroupDevice = {
					deviceSerials:[],
					groupNo: '',
					groupId:''
				};
				this.showPermissionDia = false
			},
		},
	}
</script>

<style lang="less" scoped>
	.app-container {
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	.left-side {
		::v-deep .el-scrollbar__wrap {
			overflow-x: hidden;
		}
	}

	.el-main {
		overflow: hidden;
	}

	.container {
		flex: 1;
		display: flex;
		flex-direction: column;
		height: 100%;
		// padding: 18px 8px 8px;

		.center-content {
			flex: 1;
		}
	}
	
	.equipment-group-title{
		padding: 10px;
	}
	
	.tree-equipment-group {
		
	}

	.top-bar {
		margin-bottom: 10px;
	}
</style>
