<!-- 智能管理 - 人员管理 -->
<template>
	<div class="app-container" ref="appContainer">
		<div class="container">
			<div class="top-bar">
				<!-- 查询及操作区域 -->
				<el-form :inline="true">
					<el-form-item>
						<el-select v-model="reqData.banzubumen" clearable placeholder="请选择年级组">
							<el-option v-for="(item, index) in banzubumens" :key="index" :label="item.zzmc"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-input v-model="reqData.username" clearable placeholder="请输入人员姓名"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="searchLog"><i class="el-icon-search"></i>查询</el-button>
						<el-button type="primary" @click="resetSearch"><i class="el-icon-refresh"></i>重置</el-button>

						<el-button v-if="loginUser.Roleid == 1 || loginUser.Roleid == 3" type="primary"
							@click="showAddOrEditDia = true"><i class="el-icon-plus"></i>添加
						</el-button>
						<el-button v-if="loginUser.Roleid == 1 || loginUser.Roleid == 3" type="primary"
							@click="showBatchAddDia = true"><i class="el-icon-plus"></i>批量添加
						</el-button>
						<!-- <el-button v-if="loginUser.Roleid == 1 || loginUser.Roleid == 3" type="primary"
							@click="showImportDia = true"><i class="el-icon-download"></i>导入数据
						</el-button> -->

						<el-button type="primary" @click="exportData"><i class="el-icon-upload2"></i>导出数据</el-button>
						<el-button type="primary" @click="openSetDeptClick">设置年级组</el-button>
						<el-button type="success" @click="batchBindWorkerClick">绑定限权分组</el-button>
						<!-- <el-button type="success" @click="batchSyncDeviceGroupClick">批量同步设备组</el-button> -->
					</el-form-item>
				</el-form>
			</div>

			<div class="center-content" ref="tbContainer">
				<!-- 表格区域 -->
				<el-table ref="tbWorkers" v-loading="loadingLogData" :data="tbWorkers" border :height="tbHeight" stripe
					:header-cell-style="{textAlign: 'center'}" :cell-style="{ textAlign: 'center' }"
					tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
					<el-table-column type="selection" width="50"></el-table-column>
					<el-table-column type="index" label="序号" width="80">
					</el-table-column>
					<el-table-column prop="banzubumenName" label="所在年级组">
					</el-table-column>
					<el-table-column prop="xingming" label="人员姓名">
					</el-table-column>
					<el-table-column prop="shoujihao" label="手机号">
					</el-table-column>
					<el-table-column prop="headerImg" label="头像">
						<template slot-scope="scope">
							<el-image :src="getOssImgThumb(scope.row.headerImg, 32, 32)"
								:preview-src-list="[scope.row.headerImg]">
							</el-image>
						</template>
					</el-table-column>
					<el-table-column prop="permsGroupName" label="已绑定权限组">
					</el-table-column>
					<el-table-column label="操作" width="360">
						<template slot-scope="scope">
							<!-- <el-button size="mini" type="success" @click="syncDeviceWorker(scope.row)">同步设备组</el-button> -->
							<el-button size="mini" type="success" @click="bindWorker(scope.row)">绑定限权分组</el-button>
							<el-button size="mini" type="primary" @click="editWorker(scope.row)">修改</el-button>
							<el-button size="mini" type="danger" @click="deleteWorker(scope.row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<!-- 分页区域 -->
			<el-pagination class="page-bar" @size-change="changePageSize" @current-change="changeCurrentPage"
				:current-page="reqData.page" :page-size="reqData.limit" :total="totalLogCount"
				:page-sizes="[10, 20, 30, 40, 50]" layout="total, sizes, prev, pager, next">
			</el-pagination>
		</div>
		<!-- 弹窗区域 -->
		<el-dialog v-if="loginUser.Roleid == 1 || loginUser.Roleid == 3" class="dialog-add-equipment"
			:visible.sync="showImportDia" title="导入人员" :close-on-click-modal="false" width="400px"
			custom-class="dialog-center">
			<div v-loading="loadingUpload">
				<el-upload class="upload-demo" style="line-height: normal;" ref="upload" :limit="1" :multiple="false"
					action="/BasicData/Worker/ImportData" drag accept=".xls,.xlsx" :before-upload="beforeUpload"
					:on-success="uploadSuccess" :on-exceed="fileExceed">
					<i class="el-icon-upload"></i>
					<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
					<div class="el-upload__tip" slot="tip"
						style="display: flex; align-items: center; justify-content: space-between;">
						<span>只能上传Excel文件</span>
						<el-link type="danger" :underline="false" href="/Template/template_face.xlsx" download="人员模板"
							style="line-height: normal;">Excel模板下载<i class="el-icon-download"></i></el-link>
					</div>
				</el-upload>
			</div>
		</el-dialog>

		<el-dialog :title="addOrEditDiaTitle" :visible.sync="showAddOrEditDia" :close-on-click-modal="false"
			width="500px" @close="cancelAddOrEdit">
			<div>
				<el-form :model="formWorker" :rules="rules" ref="formWorker" label-width="90px">
					<el-form-item prop="banzubumen" label="年级组">
						<el-select v-model="formWorker.banzubumen" placeholder="请选择年级组" style="width: 100%;">
							<el-option v-for="(item, index) in banzubumens" :key="index" :label="item.zzmc"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item prop="xingming" label="姓名">
						<el-input v-model="formWorker.xingming" size="large" placeholder="请输入姓名"></el-input>
					</el-form-item>
					<el-form-item prop="shoujihao" label="手机号">
						<el-input v-model="formWorker.shoujihao" size="large" placeholder="请输入手机号" :disabled="disabledPhone"></el-input>
					</el-form-item>
					<el-form-item prop="headerImgUrl" label="人脸">
						<el-upload class="avatar-uploader" :action="uploadUrl" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
							<img v-if="headerImgUrl" :src="headerImgUrl" class="avatar">
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancelAddOrEdit">取 消</el-button>
				<el-button type="primary" @click="confirmAddOrEdit">确 定</el-button>
			</span>
		</el-dialog>

		<el-dialog title="设置年级组" :visible.sync="dialogFormVisible" :close-on-click-modal="false" width="500px"
			@close="closeDeptDia">
			<div>
				<el-tree class="tree-dept" ref="treeDept" :data="banzubumens" :highlight-current="true"
					:props="defaultProps" :default-expand-all="true" node-key="id" :expand-on-click-node="false">
				</el-tree>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="saveSetDeptClick">确 定</el-button>
			</span>
		</el-dialog>

		<!--绑定权限分组-->
		<el-dialog title="绑定权限分组" :visible.sync="showPermissionDia" :close-on-click-modal="false" width="500px">
			<div>
				<el-select v-model="permWorker.groupId" placeholder="请选择权限分组" style="width: 100%;">
					<el-option v-for="(item, index) in permissions" :key="index" :label="item.groupName"
						:value="item.groupId">
					</el-option>
				</el-select>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancelPermission">取 消</el-button>
				<el-button type="primary" @click="confirmPermission">确 定</el-button>
			</span>
		</el-dialog>

		<!--绑定设备组-->
		<el-dialog title="绑定设备组" :visible.sync="showSyncDeviceDia" :close-on-click-modal="false" width="500px">
			<div>
				<el-select v-model="deviceWorker.deviceNo" placeholder="请选择设备组" style="width: 100%;">
					<el-option v-for="(item, index) in syncDeviceGroup" :key="index" :label="item.groupName"
						:value="item.groupNo">
					</el-option>
				</el-select>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancelSyncDevices">取 消</el-button>
				<el-button type="primary" @click="confirmDeviceGroup">确 定</el-button>
			</span>
		</el-dialog>
		
		<!-- 批量添加 -->
		<el-dialog v-if="loginUser.Roleid == 1 || loginUser.Roleid == 3" class="dialog-add-equipment"
			:visible.sync="showBatchAddDia" title="批量添加人员" :close-on-click-modal="false" width="800px"
			custom-class="dialog-center" @close='cancelBathAdd'>
			<div>
				<el-form :model="formBatchUpload" ref="formBatchUpload" label-width="90px">
					<el-form-item prop="banzubumen" label="年级组" :rules="[{ required: true, message: '请选择年级组', trigger: 'blur' }]">
						<el-select v-model="formBatchUpload.banzubumen" placeholder="请选择年级组" style="width: 100%;">
							<el-option v-for="(item, index) in banzubumens" :key="index" :label="item.zzmc"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="人员列表">
						<div style="max-height: 300px;overflow-y: auto;">
							<el-upload ref="uploadBath" class="batch-uploader" :action="batchUploadUrl" list-type="picture-card" multiple :limit="50" accept=".jpg"
								:on-success="handleUploadSuccess" :before-upload="beforeAvatarUpload" :auto-upload="false" :headers="uploadHeader" :data="formBatchUpload"
								:on-exceed="uploadBatchExceed">
								<i slot="default" class="el-icon-plus"></i>
								<div slot="tip" class="el-upload__tip">格式：姓名-手机号.jpg，且不超过200kB，一次最多50张图片（上传后自动移除）</div>
							</el-upload>
						</div>
					</el-form-item>
					<el-form-item label="错误列表">
						<el-table :data="batchUploadErrorData" border :max-height="300" stripe
							:header-cell-style="{textAlign: 'center'}" :cell-style="{ textAlign: 'center' }"
							tooltip-effect="dark" style="width: 100%;line-height: initial;">
							<el-table-column type="index" label="序号" width="60">
							</el-table-column>
							<el-table-column prop="name" label="人员图片">
							</el-table-column>
							<el-table-column prop="error" label="失败原因" show-overflow-tooltip>
							</el-table-column>
						</el-table>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancelBathAdd">取 消</el-button>
				<el-button @click="resetBathAdd">重 置</el-button>
				<el-button type="primary" @click="confirmBathAdd">添 加</el-button>
			</span>
		</el-dialog>

	</div>
</template>
<script>
	export default {
		data() {
			return {
				// 需要灵活获取
				loginUser: {
					Roleid: 0
				},
				dialogFormVisible: false,

				multipleSelection: [], // 选中的数据
				loadingLogData: true,
				banzubumens: [],
				reqData: {
					page: 1,
					limit: 10,
					banzubumen: '',
					username: ''
				},
				totalLogCount: 0,
				tbWorkers: [],
				tbHeight: 300,

				showImportDia: false,
				loadingUpload: false,

				//上图请求地址
				uploadUrl:'',

				// departments: [],
				defaultProps: {
					children: 'children',
					label: 'zzmc'
				},

				addOrEditDiaTitle: '添加人员',
				showAddOrEditDia: false,

				headerImgUrl:'',
				
				disabledPhone: false,
				formWorker: {
					id: '',
					xingming: '',
					shoujihao: '',
					banzubumen: '',
					headerImgUrl:''
				},
				rules: {
					xingming: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}],
					shoujihao: [{
							required: true,
							message: '请输入手机号',
							trigger: 'blur'
						},
						{
							validator: (rule, value, callback) => {
								if (/^1[3-9]\d{9}$/.test(value)) {
									callback()
								} else {
									callback(new Error('手机号不正确'))
								}
							},
							trigger: 'blur'
						}
					],
					banzubumen: [{
						required: true,
						message: '请选择年级组',
						trigger: 'blur'
					}]

				},
				//绑定权限分组
				permWorker:{
					shoujihaos:[],
					groupId:''
				},
				permissions:[],
				groupId:'',
				showPermissionDia: false,
				
				uploadHeader: {
					Authorization: ''
				},
				batchUploadUrl: '',
				showBatchAddDia: false,
				formBatchUpload: {
					banzubumen: ''
				},
				batchUploadErrorData: [],

				//同步设备组
				showSyncDeviceDia: false,
				syncDeviceGroup:[],//设备组
				deviceWorker:{
					deviceNo:'',//设备编号
					deviceWorker:[]//记录需要同步的人员集合
				}
			}
		},
		created() {
			this.loginUser = JSON.parse(window.sessionStorage.getItem('user'));
			
			//拼接上传图片地址
			this.uploadUrl = this.$http.defaults.baseURL + '/BasicData/Worker/ExportFile';
			
			this.batchUploadUrl = this.$http.defaults.baseURL + '/BasicData/Worker/ExportFileForBatch';
			this.uploadHeader.Authorization = window.sessionStorage.getItem('token')
		},
		mounted() {
			this.loadLogData();

			this.loadGradeGroups();

			//加载设备组
			this.loadDeviceGroup();

			//加载权限分组
			this.loadPermissionGroup();

			this.$erd.listenTo(this.$refs.appContainer, (element) => {
				this.tbHeight = 100
				this.$nextTick(() => {
					this.tbHeight = this.$refs.tbContainer.offsetHeight
				})
			})
		},
		methods: {
			loadDeviceGroup(){
				//加载设备组
				this.$http.post('/BasicData/Worker/GetDeviceGroups').then(res => {
					console.log('加载设备组',res)
					if (res.data.errcode == 0) {
						this.syncDeviceGroup = res.data.result
					}
				})
			},
			batchSyncDeviceGroupClick(){
				//批量同步设备组
				if (this.multipleSelection.length > 0) {
					this.deviceWorker={
						workers:[],
						deviceNo:''
					};
					//人员集合
					this.deviceWorker.workers = this.multipleSelection;	
					this.showSyncDeviceDia = true;
				} else {
					this.$message({
						message: '请选中人员后在同步设备组',
						type: 'warning'
					})
				}
			},
			syncDeviceWorker(worker){
				//同步设备组
				this.deviceWorker={
					workers:[],
					deviceNo:''
				};
				this.deviceWorker.workers.push(worker);
				this.showSyncDeviceDia = true;
			},
			confirmDeviceGroup(){
				this.$http.post('/BasicData/Worker/SyncDeviceGroups', this.deviceWorker).then(res => {
				   if (res.data.errcode == 0) {
				       this.$message({
				           message: '同步组成功',
				           type: 'success'
				       });
					   this.cancelSyncDevices();
				   } else {
				       this.$message({
				           message: res.data.errmsg,
				           type: 'error'
				       });
				   }
				})
			},
			cancelSyncDevices() { // 取消，关闭对话框
				this.showSyncDeviceDia = false;
				
				//取消选中
				this.$refs.tbWorkers.clearSelection();
			},
			batchBindWorkerClick(){
				//批量绑定权限分组
				if (this.multipleSelection.length > 0) {
					// 打开弹框
					
					let shoujihaos = this.multipleSelection.map(s => {
						return s.shoujihao
					})

					//手机号集合
					this.permWorker.shoujihaos = shoujihaos;

					this.$http.post('/BasicData/Worker/BindPermissionGroups', this.permWorker).then(res => {
						if (res.data.errcode == 0) {
							this.$message({
								message: '绑定权限分组成功',
								type: 'success'
							});
							
						} else {
							this.$message({
								message: res.data.errmsg,
								type: 'error'
							});
						}
					})
					
					//取消选中
					this.$refs.tbWorkers.clearSelection();

				} else {
					this.$message({
						message: '请选中人员后在绑定权限分组',
						type: 'warning'
					})
				}
			},
			confirmPermission() { // 绑定权限分组							
				this.$http.post('/BasicData/Worker/BindPermissionGroups', this.permWorker).then(res => {
				   if (res.data.errcode == 0) {
				       this.$message({
				           message: '绑定权限分组成功',
				           type: 'success'
				       });
					   this.cancelPermission();
				   } else {
				       this.$message({
				           message: res.data.errmsg,
				           type: 'error'
				       });
				   }
				})
			},
			cancelPermission() { // 取消，关闭对话框
				this.showPermissionDia = false
			},
			loadPermissionGroup(){
				this.$http.post('/BasicData/Worker/GetPermissionGroups').then(res => {
					if (res.data.errcode == 0) {
						this.permissions = res.data.result
					}
				})
			},
			bindWorker(worker){
				//绑定权限分组
				this.permWorker={
					shoujihaos:[],
					groupId:''
				};
				this.permWorker.shoujihaos.push(worker.shoujihao);
				this.showPermissionDia = true;
			},		
			handleAvatarSuccess(res, file) {
				console.log('上传结果',res)
				//this.formWorker.headerImgUrl = URL.createObjectURL(file.raw);
				if(res.Code==0){
					this.headerImgUrl = res.ShowSrc;
					this.formWorker.headerImgUrl = res.Src;
				}
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg';
				const isLt200K = file.size / 1024 < 200;

				if (!isJPG) {
					if(this.showBatchAddDia){
						this.batchUploadErrorData.push({
							name: file.name,
							error: '上传人脸图片只能是 JPG 格式'
						});
					}
					this.$message.error('上传人脸图片只能是 JPG 格式!');
				}
				if (!isLt200K) {
					if(this.showBatchAddDia){
						this.batchUploadErrorData.push({
							name: file.name,
							error: '上传人脸图片大小不能超过 200KB'
						});
					}
					this.$message.error('上传人脸图片大小不能超过 200KB!');
				}
				return isJPG && isLt200K;
			},
			getOssImgThumb(url, h, w) {
			    if (!url) {
			        return '';
			    }
			    if (!h) h = 32;
			    if (!w) w = 32;
			    let suffix = '?x-oss-process=image/resize,m_fixed,h_' + h + ',w_' + w;
			    if (url.indexOf('http://') > -1 || url.indexOf('https://') > -1) {
			        return url + suffix;
			    } else {
			        return '';
			    }
			},
			loadGradeGroups() {
				this.$http.post('/BasicData/Worker/GetGradeGroups').then(res => {
					if (res.data.errcode == 0) {
						this.banzubumens = res.data.result
					}
				})
			},
			changePageSize(val) {
				this.reqData.limit = val
				this.reloadLogData()
			},
			changeCurrentPage(val) {
				this.reqData.page = val
				this.loadLogData()
			},
			reloadLogData(page) {
				this.tbWorkers = []
				if (page) {
					this.reqData.page = page
				} else {
					this.reqData.page = 1
				}
				this.loadLogData()
			},
			loadLogData() {
				this.loadingLogData = true
				this.$http.post('/BasicData/Worker/List', this.reqData).then(res => {
					this.loadingLogData = false
					if (res.data.errcode == 0) {
						this.totalLogCount = res.data.result.count
						this.tbWorkers = res.data.result.list
					}
				})
			},
			searchLog() {
				this.reloadLogData()
			},
			resetSearch() {
				this.reqData.banzubumen = ''
				this.reqData.username = ''
			},
			exportData() {
				const loading = this.$loading()
				this.$http.post('/BasicData/Worker/ExportData', this.reqData).then(res => {
					loading.close()
					if (res.data.errcode == 0) {
						this.$message({
							message: '导出成功！',
							type: 'success',
							duration: 500,
							onClose: function(e) {
								const a = document.createElement('a')
								a.href = res.data.result;
								a.click();
							}
						})
					} else {
						this.$message({
							message: '导出失败！',
							type: 'error'
						})
					}
				})
			},
			fileExceed(files, fileList) {
				if (files.length > 0) {
					this.$message({
						message: '只能选择一个Excel文件！',
						type: 'info'
					})
				}
			},
			beforeUpload(file) {
				this.loadingUpload = true
			},
			uploadSuccess(response, file, fileList) {
				this.loadingUpload = false
				if (response.errcode == 0) {
					this.$refs.upload.clearFiles()
					this.showImportDia = false
					this.$message({
						message: '导入成功！',
						type: 'success'
					})
					this.reloadLogData()
					this.loadGradeGroups()
				} else {
					this.$message({
						message: response.errmsg,
						type: 'error'
					})
				}
			},
			cancelImportData() {
				this.$refs.upload.clearFiles()
				this.showImportDia = false
			},
			confirmImport() {
				if (this.$refs.upload.uploadFiles.length == 0) {
					this.$message({
						message: '请选择文件！',
						type: 'info'
					})
					return
				}
				this.$refs.upload.submit()
			},
			editWorker(worker) {
				console.log('编辑',worker);
				this.formWorker = JSON.parse(JSON.stringify(worker))
				
				this.disabledPhone = true;

				this.addOrEditDiaTitle = '编辑人员'
				this.showAddOrEditDia = true
				this.$refs.tbWorkers.setCurrentRow(worker)
			},
			deleteWorker(worker) {
				this.$refs.tbWorkers.setCurrentRow(worker)
				this.$confirm('确定要删除该人员吗', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					closeOnClickModal: false
				}).then(() => {
					this.$http.post('/BasicData/Worker/Delete', {
						id: worker.id,
						idcard: worker.shoujihao
					}).then(res => {
						if (res.data.errcode == 0) {
							this.$message({
								message: '删除成功！',
								type: 'success'
							})

							this.reloadLogData(this.reqData.page)
						} else {
							this.$message({
								message: '删除失败！',
								type: 'error'
							})
						}
					})
				}).catch(() => {

				})
			},
			cancelAddOrEdit() { // 取消，关闭对话框
				this.$refs.formWorker.clearValidate();
				this.showAddOrEditDia = false
				
				this.formWorker = {
					id: '',
					xingming: '',
					shoujihao: '',
					banzubumen: '',
					headerImgUrl:''
				}
				this.disabledPhone = false;
			},
			confirmAddOrEdit() { // 保存
				this.$refs.formWorker.validate((valid) => {
					if (valid) {
						if (this.formWorker.id) {
							this.$http.post('/BasicData/Worker/Update', this.formWorker).then(res => {
							   if (res.data.errcode == 0) {
							       this.$message({
							           message: '修改成功',
							           type: 'success'
							       });

							       this.reloadLogData();

							       this.cancelAddOrEdit();
							   } else {
							       this.$message({
							           message: res.data.errmsg,
							           type: 'error'
							       });
							   }
							})
						} else {
							this.$http.post('/BasicData/Worker/Add', this.formWorker).then(res => {
								console.log("添加",res);
								if (res.data.errcode == 0) {
									this.$message({
										message: '添加成功',
										type: 'success'
									})

									this.reloadLogData()

									this.cancelAddOrEdit()
								} else {
									this.$message({
										message: res.data.errmsg,
										type: 'error'
									})
								}
							})
						}
					} else {
						return false
					}
				})
			},
			closeAddOrEditDia() {
				this.$refs.formWorker.resetFields()
			},
			// loadDepartments() { // 获取所有部门
			//    this.$http.post('/BasicData/Department/GetDepartmentTree').then(res => {
			//        if (res.data.errcode == 0) {
			//            this.departments = res.data.result;
			//        }
			//    })
			// },
			handleSelectionChange(val) {
				// 表格中的复选框
				this.multipleSelection = val
			},
			closeDeptDia() {
				this.$refs.treeDept.setCurrentKey(null)
			},
			openSetDeptClick() {
				if (this.multipleSelection.length > 0) {
					// 打开弹框
					this.dialogFormVisible = true
				} else {
					this.$message({
						message: '请选中人员后在设置年级组',
						type: 'warning'
					})
				}
			},
			saveSetDeptClick() {
				// 批量保存设置人员部门
				const nodeDept = this.$refs.treeDept.getCurrentNode()

				if (!nodeDept) {
					this.$message({
						message: '请选择年级组',
						type: 'warning'
					})
					return
				}

				const personIds = this.multipleSelection.map(s => {
					return s.id
				})

				this.$http.post('/BasicData/Worker/SetPersonDept', {
					personIds: personIds,
					deptId: nodeDept.id,
					deptName: nodeDept.zzmc
				}).then(res => {
					if (res.data.errcode == 0) {
						this.$message({
							message: '修改成功',
							type: 'success'
						})

						this.loadLogData()
					} else {
						this.$message({
							message: '修改失败',
							type: 'error'
						})
					}
				})
				this.dialogFormVisible = false
			},
			uploadBatchExceed(files, fileList) {
				if (files.length > 50) {
					this.$message({
						message: '最多选择50张图片！',
						type: 'info'
					})
				}
			},
			handleUploadSuccess(res, file, fileList){
				console.log('上传结果', res, file)
				let i = fileList.findIndex(f => f.uid == file.uid);
				fileList.splice(i, 1);
				
				if (res.Code == 1) {
					this.batchUploadErrorData.push({
						name: file.name,
						error: res.Msg
					});
				}
			},
			cancelBathAdd(){
				this.$refs.uploadBath.clearFiles()
				this.$refs.formBatchUpload.clearValidate()
				this.formBatchUpload.banzubumen = '';
				this.batchUploadErrorData = [];
				this.showBatchAddDia = false;
			},
			resetBathAdd(){
				this.$refs.uploadBath.clearFiles()
				this.batchUploadErrorData = [];
			},
			confirmBathAdd(){
				if(this.$refs.uploadBath.uploadFiles.length == 0){
					this.$message({
						message: '请按格式要求上传人员图片',
						type: 'error'
					})
					return;
				}
				
				this.$refs.formBatchUpload.validate((valid) => {
					if(valid){
						this.$refs.uploadBath.submit()
					}else{
						
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.app-container {
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	.container {
		flex: 1;
		display: flex;
		flex-direction: column;
		/*height: 100%;*/
		padding: 18px 8px 8px;

		.center-content {
			flex: 1;
		}
	}

	// .center-content {
	//     flex: 1;

	//     .el-scrollbar__wrap {
	//         overflow-x: hidden;
	//     }
	// }

	.user-statistics {
		display: flex;
		align-items: center;
		font-size: 16px;
		margin-bottom: 20px;

		.count {
			color: #f56c6c;
			font-weight: bold;
		}

		.user-total {}

		.user-today {
			margin-left: 20px;
		}
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}
	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}
	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
	
	.batch-uploader{
		::v-deep .el-upload--picture-card{
			width: 80px;
			height: 80px;
			line-height: 80px;
		}
		
		::v-deep .el-upload-list--picture-card .el-upload-list__item{
			width: 80px;
			height: 80px;
		}
	}
</style>
