<!-- 手机端 - 人员照片采集 -->
<template>
	<div class="app-container">
		<div class="container">
			<div class="top-title">人员头像上传采集</div>
			<div class="form-box">
				<van-form validate-first :disabled="disabledSubmit" @submit="onSubmit">
					<van-field v-model="formUser.phone" name="phone" type="tel" label="手机号" placeholder="请输入手机号" required :rules="[{ required: true, message: '请输入手机号' },
			                           {pattern: /^1[3-9]\d{9}$/, message: '手机号格式错误！'}]"></van-field>
					<van-field name="uploader" label="头像" required :rules="[{ required: true, message: '请上传头像' }]">
						<template #input>
							<van-uploader v-model="uploader" :max-count="1" :disabled="disabledSubmit"
								:after-read="afterRead" :before-delete="beforeDelete" />
						</template>
					</van-field>
					<div style="margin: 20px 0;">
						<van-button round block type="info" :disabled="disabledSubmit || !isUploadSuccess"
							native-type="submit">提交</van-button>
					</div>
				</van-form>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				formUser: {
					phone: '',
					filename: '',
				},
				uploader: [],
				disabledSubmit: false,
				isUploadSuccess: false,
			}
		},
		created() {
			const meta = document.createElement('meta');
			meta.content =
				'width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no,viewport-fit=cover';
			meta.name = 'viewport';
			document.getElementsByTagName('head')[0].appendChild(meta)

			document.querySelector('html').setAttribute('style', 'min-width:initial !important;');
			document.querySelector('body').setAttribute('style', 'min-width:initial !important;');
			this.$nextTick(() => {
				document.getElementById('app').setAttribute('style', 'min-width:initial !important;');
			})
		},
		methods: {
			afterRead(data) {
				let that = this,
					file = data.file;
				this.canvasDataURL(file, function(blob) {
					var aafile = new File([blob], file.name, {
						type: file.type
					})
					var isLt1M;
					if (file.size < aafile.size) {
						isLt1M = file.size
					} else {
						isLt1M = aafile.size
					}

					if (isLt1M / 1024 / 1024 > 2) {
						that.$toast.fail('上传图片过大！')
						return;
					}

					let params = new FormData();
					params.append('file', aafile);
					let config = {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					}
					let loading = that.$toast.loading({
						message: '上传中...',
						forbidClick: true,
						duration: 0
					})
					that.$http.post('/BasicData/Worker/UploadImg', params, config).then(res => {
						loading.clear()
						if (res.data.errcode == 0) {
							that.formUser.filename = res.data.errmsg;
							that.$toast.success('上传成功')
							that.isUploadSuccess = true;
						} else {
							that.$toast.fail(res.data.errmsg)
						}
					})
				})
			},
			beforeDelete(file) {
				this.formUser.filename = '';
				return true;
			},
			onSubmit(values) {
				this.disabledSubmit = true;
				let loading = this.$toast.loading({
					message: '提交中...',
					forbidClick: true,
					duration: 0
				})
				this.$http.post('/BasicData/Worker/SaveUploadPhoto', this.formUser).then(res => {
					loading.clear()
					if (res.data.errcode == 0) {
						this.$toast.success('提交成功')
					} else {
						this.disabledSubmit = false;
						this.$toast.fail(res.data.errmsg)
					}
				})
			},
			canvasDataURL(file, callback) { //压缩转化为base64
				let that = this;
				var reader = new FileReader()
				reader.readAsDataURL(file)
				reader.onload = function(e) {
					const img = new Image()
					const quality = 0.5 // 图像质量
					const canvas = document.createElement('canvas')
					const drawer = canvas.getContext('2d')
					img.src = this.result
					img.onload = function() {
						canvas.width = img.width
						canvas.height = img.height
						drawer.drawImage(img, 0, 0, canvas.width, canvas.height)
						that.convertBase64UrlToBlob(canvas.toDataURL(file.type, quality), callback);
					}
				}
			},
			convertBase64UrlToBlob(urlData, callback) { //将base64转化为文件格式
				const arr = urlData.split(',')
				const mime = arr[0].match(/:(.*?);/)[1]
				const bstr = atob(arr[1])
				let n = bstr.length
				const u8arr = new Uint8Array(n)
				while (n--) {
					u8arr[n] = bstr.charCodeAt(n)
				}
				callback(new Blob([u8arr], {
					type: mime
				}));
			}
		}
	}
</script>

<style lang="less" scoped>
	.app-container {
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	.container {
		flex: 1;
		display: flex;
		flex-direction: column;
		/*height: 100%;*/
		padding: 18px 8px 8px;

		.top-title {
			font-size: 30px;
			text-align: center;
			margin: 30px 0;
		}

		.form-box {
			padding: 30px;
		}
	}
</style>
