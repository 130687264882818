<!-- 菜单管理 -->
<template>
	<div class="app-container" ref="appContainer">
		<div class="container sys-manage-menu">
			<div class="top-bar">
				<el-form :inline="true" class="demo-form-inline">
					<el-form-item label="菜单名称">
						<el-input v-model="searchMenu" placeholder="菜单名称"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="filterMenu">查询</el-button>
						<el-button type="primary" @click="addRootMenu">新增根菜单</el-button>
					</el-form-item>
				</el-form>
			</div>
			<div class="center-content" ref="tbContainer">
				<el-table ref="tbMenus" :data="filterTbMenus" style="width: 100%;" :height="tbHeight" row-key="id"
					border stripe default-expand-all :highlight-current-row="true"
					:tree-props="{children: 'children', hasChildren: 'hasChildren'}">
					<el-table-column prop="name" label="菜单名称" width="260">
					</el-table-column>
					<el-table-column prop="icon" label="图标" align="center" width="160">
						<template slot-scope="scope">
							<i :class="scope.row.icon" style="font-size:18px;"></i>
						</template>
					</el-table-column>
					<el-table-column prop="nameSpace" label="命名空间" align="center">
					</el-table-column>
					<el-table-column prop="controller" label="控制器" align="center">
					</el-table-column>
					<el-table-column prop="action" label="方法" align="center">
					</el-table-column>
					<el-table-column prop="isshow" label="是否显示" align="center" width="160">
						<template slot-scope="scope">
							<i v-if="scope.row.isshow == 1" class="el-icon-circle-check"
								style="color: #67C23A; font-size: 18px;"></i>
							<i v-else class="el-icon-circle-close" style="color: #F56C6C; font-size: 18px; "></i>
						</template>
					</el-table-column>
					<el-table-column prop="code" label="编码" align="center" width="160">
					</el-table-column>
					<el-table-column label="操作" align="center" width="210">
						<template slot-scope="scope">
							<el-button size="medium" type="text" @click.stop="addSubMenu(scope.row)">添加子菜单</el-button>
							<el-button size="medium" type="text" @click.stop="editMenu(scope.row)">编辑</el-button>
							<el-button size="medium" type="text" @click.stop="deleteMenu(scope.row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>

		<el-dialog :title="addOrEditDiaTitle" :visible.sync="showAddOrEditDia" :close-on-click-modal="false"
			width="500px">
			<div>
				<el-form :model="formMenu" :rules="rules" ref="formMenu" label-width="90px">
					<el-form-item label="父菜单" v-show="showParentMenu">
						<el-input v-model="formMenu.pname" :disabled="true" size="large"></el-input>
					</el-form-item>
					<el-form-item prop="name" label="菜单名称">
						<el-input v-model="formMenu.name" size="large" placeholder="请输入菜单名称"></el-input>
					</el-form-item>
					<el-form-item prop="nameSpace" label="命名空间">
						<el-input v-model="formMenu.nameSpace" size="large" placeholder="请输入命名空间"></el-input>
					</el-form-item>
					<el-form-item prop="controller" label="控制器">
						<el-input v-model="formMenu.controller" size="large" placeholder="请输入控制器"></el-input>
					</el-form-item>
					<el-form-item prop="action" label="方法">
						<el-input v-model="formMenu.action" size="large" placeholder="请输入方法"></el-input>
					</el-form-item>
					<el-form-item prop="code" label="编码">
						<el-input v-model="formMenu.code" size="large" placeholder="请输入编码"></el-input>
					</el-form-item>
					<el-form-item prop="icon" label="图标">
						<el-popover v-model="showIconPicker" placement="top-start" width="550" trigger="click">
							<div class="icons-container">
								<span class="icon-item" v-for="(item,index) in iconsData" :key="index"
									@click="chooseIcon(item)">
									<i :class="item.icon"></i>
								</span>
							</div>
							<span slot="reference">
								<el-button v-if="null == formMenu.icon || formMenu.icon == ''">选择图标</el-button>
								<el-button v-else style="padding: 10px; font-size: 28px;position: relative;">
									<i :class="formMenu.icon"></i>
									<i class="el-icon-circle-close delete-icon" @click.stop="deleteIcon"></i>
								</el-button>
							</span>
						</el-popover>
					</el-form-item>
					<el-form-item prop="isshow" label="是否显示">
						<el-radio-group v-model="formMenu.isshow">
							<el-radio :label="1">显示</el-radio>
							<el-radio :label="0">不显示</el-radio>
						</el-radio-group>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancelAddOrEdit">取 消</el-button>
				<el-button type="primary" @click="confirmAddOrEdit">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				loadingData: true,
				searchMenu: '',
				tbMenus: [],
				tbHeight: 300,

				filterTbMenus: [],

				showIconPicker: false,
				iconsData: [],

				addOrEditDiaTitle: '新增菜单',
				showAddOrEditDia: false,

				showParentMenu: true,
				formMenu: {
					id: 0,
					name: '',
					nameSpace: '',
					controller: '',
					action: '',
					icon: '',
					pid: '',
					pname: '',
					isshow: 1,
					code: ''
				},
				rules: {
					name: [{
						required: true,
						message: '请输入菜单名称',
						trigger: 'blur'
					}],
				}
			}
		},
		mounted() {
			this.loadData();

			this.getIcons();

			this.$erd.listenTo(this.$refs.appContainer, (element) => {
				this.tbHeight = 100;
				this.$nextTick(() => {
					this.tbHeight = this.$refs.tbContainer.offsetHeight;
				})
			})
		},
		methods: {
			getIcons() {
				this.$http.get('/Scripts/icons.json').then(res => {
					this.iconsData = res.data;
				})
			},
			loadData() { // 获取所有菜单
				this.tbMenus = [];
				this.$http.post('/SysManage/Menu/GetMenuTree').then(res => {
					this.loadingData = false;
					if (res.data.errcode == 0) {
						this.tbMenus = res.data.result;
						if (this.searchMenu) {
							this.filterMenu();
						} else {
							this.filterTbMenus = this.deepClone(this.tbMenus);
						}
					}
				})
			},
			filterMenu() {
				this.filterTbMenus = this.deepClone(this.tbMenus);
				this.filterMenu_Custom(this.filterTbMenus);
			},
			filterMenu_Custom(tree = []) {
				if (!tree.length) return []
				for (let i = tree.length - 1; i >= 0; i--) {
					if (tree[i].children && tree[i].children.length) {
						this.filterMenu_Custom(tree[i].children)
					}

					if (tree[i].name.indexOf(this.searchMenu) == -1 && (!tree[i].children || tree[i].children.length ==
							0)) {
						tree.splice(i, 1);
					}
				}
			},
			getMenuParent(tree, pid) {
				for (const menu of tree) {
					if (menu.id == pid)
						return menu;
					if (menu.children) {
						const res = this.getMenuParent(menu.children, pid);
						if (res)
							return res;
					}
				}
				return null;
			},
			addRootMenu() { // 添加根菜单
				this.formMenu = {
					id: 0,
					name: '',
					nameSpace: '',
					controller: '',
					action: '',
					icon: '',
					pid: '',
					pname: '',
					isshow: 1,
					code: ''
				};
				this.showParentMenu = false;
				this.addOrEditDiaTitle = '新增根菜单';
				this.showAddOrEditDia = true;
				this.$nextTick(() => {
					this.$refs.formMenu.resetFields();
				})
			},
			addSubMenu(menu) { // 添加子菜单
				this.formMenu = {
					id: 0,
					name: '',
					nameSpace: '',
					controller: '',
					action: '',
					icon: '',
					pid: menu.id,
					pname: menu.name,
					isshow: 1,
					code: ''
				};
				this.showParentMenu = true;
				this.addOrEditDiaTitle = '添加子菜单';
				this.showAddOrEditDia = true;
				this.$nextTick(() => {
					this.$refs.formMenu.resetFields();
				})
				this.$refs.tbMenus.setCurrentRow(menu)
			},
			editMenu(menu) { // 编辑菜单
				this.formMenu = JSON.parse(JSON.stringify(menu));
				if (menu.pid == 0) {
					this.showParentMenu = false;
				} else {
					let prt = this.getMenuParent(this.filterTbMenus, menu.pid);
					this.formMenu.pname = prt.name;
					this.showParentMenu = true;
				}

				this.addOrEditDiaTitle = '编辑菜单';
				this.showAddOrEditDia = true;
				this.$refs.tbMenus.setCurrentRow(menu)
			},
			deleteMenu(menu) { // 删除菜单
				this.$refs.tbMenus.setCurrentRow(menu)
				this.$confirm('确定要删除该菜单吗', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					closeOnClickModal: false,
				}).then(() => {
					this.$http.post('/SysManage/Menu/DeleteMenu', {
						id: menu.id
					}).then(res => {
						if (res.data.errcode == 0) {
							this.$message({
								message: '删除成功！',
								type: 'success'
							});

							this.loadData();
						} else {
							this.$message({
								message: '删除失败！',
								type: 'error'
							})
						}
					})
				}).catch(() => {

				});
			},
			cancelAddOrEdit() { // 取消，关闭对话框
				this.$refs.formMenu.resetFields();
				this.showAddOrEditDia = false;
			},
			confirmAddOrEdit() { // 保存菜单信息
				this.$refs.formMenu.validate((valid) => {
					if (valid) {
						if (this.formMenu.id > 0) {
							this.$http.post('/SysManage/Menu/UpdateMenu', this.formMenu).then(res => {
								if (res.data.errcode == 0) {
									this.$message({
										message: '修改成功',
										type: 'success'
									});

									this.loadData();

									this.cancelAddOrEdit();
								} else {
									this.$message({
										message: '修改失败',
										type: 'error'
									});
								}
							})
						} else {
							this.$http.post('/SysManage/Menu/AddMenu', this.formMenu).then(res => {
								if (res.data.errcode == 0) {
									this.$message({
										message: '添加成功',
										type: 'success'
									});

									this.loadData();

									this.cancelAddOrEdit();
								} else {
									this.$message({
										message: '添加失败',
										type: 'error'
									});
								}
							})
						}
					} else {
						return false;
					}
				});
			},
			chooseIcon(item) {
				this.formMenu.icon = item.icon;
				this.showIconPicker = false;
			},
			deleteIcon() {
				this.formMenu.icon = "";
			},
		}
	}
</script>

<style lang="less" scoped>
	.app-container {
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	.container {
		flex: 1;
		display: flex;
		flex-direction: column;
		/*height: 100%;*/
		padding: 18px 8px 8px;

		.center-content {
			flex: 1;
		}
	}

	.icons-container {
		height: 400px;
		overflow-y: auto;
	}

	.icons-container .icon-item {
		cursor: pointer;
		margin: 8px;
		font-size: 28px;
	}

	.icons-container .icon-item:hover {
		color: #409EFF;
	}

	.delete-icon {
		color: red;
		font-size: 20px;
		position: absolute;
		top: -10px;
		right: -10px;
		background-color: #fff;
	}
</style>
