<!-- 人员排班 -->
<template>
	<div class="app-container" ref="appContainer">
		<el-container style="height: 500px; border: 1px solid #eee">
			<el-aside width="200px" style="background-color: rgb(238, 241, 246)">
				<el-tree :data="dataTree" :props="defaultProps" node-key="id" :highlight-current="true"
					:default-expand-all="true" :expand-on-click-node="false" @node-click="handleNodeClick"></el-tree>
			</el-aside>

			<el-container>
				<el-main>
					<el-header class="custom_button" style="text-align: right; font-size: 12px">
						<el-input ref="teacherInput" v-model="telName" placeholder="姓名" style="width:260px;margin-right: 10px;" clearable>
						</el-input>
						<el-button type="default" @click="searchClick">查询</el-button>
						<el-button type="primary" @click="addClick" v-show="tabName=='first'">人员排班</el-button>
						<el-button type="success" @click="editClick" v-show="tabName=='second'">修改排班</el-button>
						<el-button type="info" @click="delClick" v-show="tabName=='second'">删除排班</el-button>
					</el-header>

					<el-tabs v-model="activeName" type="card" @tab-click="handleClick">
						<el-tab-pane label="未排班人员" name="first">
							<el-table :data="notTableData" ref="multipleTable" border tooltip-effect="dark"
								@selection-change="handleSelectionChange">
								<el-table-column type="selection" width="90" align="center"></el-table-column>
								<el-table-column prop="banzubumenName" label="年级组"></el-table-column>
								<el-table-column prop="xingming" label="姓名"></el-table-column>
								<el-table-column prop="shoujihao" label="手机号"></el-table-column>
								<!-- @*<el-table-column prop="shenfenzhenghaoma" label="身份证号"></el-table-column>*@
								@*<el-table-column prop="deviceSn" label="人员编号" width="160"></el-table-column>*@ -->

							</el-table>
							<div class="block" style="margin-top:15px;">
								<el-pagination background @size-change="notHandleSizeChange"
									@current-change="notHandleCurrentChange" :current-page="notQuery.page"
									:page-sizes="[10, 15, 20, 25]" :page-size="notQuery.limit"
									layout="total, prev, pager, next, jumper" :total="notTotal"></el-pagination>
							</div>
						</el-tab-pane>
						<el-tab-pane label="已排班人员" name="second">
							<el-table :data="alreadyTableData" ref="multipleTable" border tooltip-effect="dark"
								@selection-change="handleSelectionChange">
								<el-table-column type="selection" width="90" align="center"></el-table-column>
								<el-table-column prop="banzubumenName" label="年级组"></el-table-column>
								<el-table-column prop="xingming" label="姓名"></el-table-column>
								<el-table-column prop="shoujihao" label="手机号"></el-table-column>
								<!-- @*<el-table-column prop="shenfenzhenghaoma" label="身份证号"></el-table-column>*@
								@*<el-table-column prop="deviceSn" label="人员编号" width="160"></el-table-column>*@ -->

								<el-table-column prop="arrangeName" label="排班名称"></el-table-column>
							</el-table>
							<div class="block" style="margin-top:15px;">
								<el-pagination background @size-change="alreadyHandleSizeChange"
									@current-change="alreadyHandleCurrentChange" :current-page="alreadyQuery.page"
									:page-sizes="[10, 15, 20, 25]" :page-size="alreadyQuery.limit"
									layout="total, prev, pager, next, jumper" :total="alreadyTotal"></el-pagination>
							</div>
						</el-tab-pane>
					</el-tabs>
				</el-main>
			</el-container>
		</el-container>

		<!-- 弹窗区域 -->
		<el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" :close-on-click-modal="false" width="500px">
			<div>
				<el-select v-model="selectArrange" clearable placeholder="请选择" style="width: 100%;">
					<el-option v-for="item in arrangeOptions" :key="item.uuid" :label="item.title" :value="item.uuid">
					</el-option>
				</el-select>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="saveClick">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
export default {
  data() {
    return {
      flagButton: '', // 标识按钮
      dialogTitle: '', // 弹框标题
      selectArrange: '',
      dialogFormVisible: false, // 排班弹框
      arrangeOptions: [], // 排班设置数据
      notTotal: 0, // 默认数据总数-未排班
      alreadyTotal: 0, // 默认数据总数-已排班
      telName: '', // 教师名称
      dptId: '', // 组织机构ID
      dptNames: '', // 组织机构名称
      activeName: 'first',
      tabName: 'first', // 记录tab
      notQuery: { // 未排班查询对象
        page: 1,
        limit: 10
      },
      alreadyQuery: { // 已排班查询对象
        page: 1,
        limit: 10
      },
      dataTree: [],
      defaultProps: {
        children: 'children',
        label: 'zzmc'
      },
      notTableData: [], // 未排班
      alreadyTableData: [], // 已排班
      multipleSelection: [] // 选中的数据
    }
  },
  mounted() {
    // 加载：组织机构树
    this.initTree()

    // 加载：排班设置
    this.initArrange()

    // 加载：未排班人员数据
    this.initNotTableDate(1)
  },
  methods: {
    // 点击事件
    handleNodeClick(data) {
      if (data.fzzcode) {
        this.dptNames = data.id
      } else {
        this.dptNames = ''
      }

      // 赋值左边选中值
      this.dptId = data.id

      if (this.tabName == 'second') {
        // 已排班人员
        this.initAlreadyTableData(1)
      } else {
        // 未排班人员
        this.initNotTableDate(1)
      }
    },
    handleSelectionChange(val) {
      // 表格中的复选框
      this.multipleSelection = val
    },
    handleClick(tab, event) {
      // 选项卡
      // 重置table中的选择
      this.$refs.multipleTable.clearSelection()
      this.multipleSelection = []

      this.tabName = tab.name
      // 已排班人员
      if (tab.name == 'second') {
        this.alreadyTotal = 0 // 默认数据总数-已排班
        this.telName = '' // 教师名称
        // this.dptNames = '';//组织机构名称
        this.alreadyQuery.page = 1 // 默认开始页面-未排班
        this.alreadyQuery.limit = 10 // 每页的数据条数-未排班
        // 加载：已排班人员数据
        this.initAlreadyTableData(1)
      } else {
        this.notTotal = 0 // 默认数据总数-未排
        this.telName = '' // 教师名称
        // this.dptNames = '';//组织机构名称
        this.notQuery.page = 1 // 默认开始页面-未排班
        this.notQuery.limit = 10 // 每页的数据条数-未排班
        // 加载：未排班人员数据
        this.initNotTableDate(1)
      }
    },
    searchClick() {
      this.flagButton = 'search'
      // 查询按钮
      if (this.telName.length > 0) {
        if (this.tabName == 'second') {
          // 已排班人员
          this.initAlreadyTableData(1)
        } else {
          // 未排班人员
          this.initNotTableDate(1)
        }
      } else {
        this.$message('请输入需要查询的教师')
        this.$refs.teacherInput.focus()
      }
    },
    addClick() {
      this.flagButton = 'add'
      // 人员排班

      if (this.multipleSelection.length > 0) {
        if (this.dptId.length == 0) {
          this.$message({
            message: '请选中组织机构后，在添加人员排班',
            type: 'warning'
          })
        } else {
          this.dialogTitle = '添加人员排班'
          this.dialogFormVisible = true
        }
      } else {
        this.$message({
          message: '请选择需要排班的人员',
          type: 'warning'
        })
      }
    },
    editClick() {
      this.flagButton = 'edit'
      // 编辑排班

      if (this.tabName == 'second') {
        // 已排班人员

        if (this.multipleSelection.length > 0) {
          this.dialogTitle = '修改人员排班'
          this.dialogFormVisible = true
        } else {
          this.$message({
            message: '请选择需要修改排班的人员',
            type: 'warning'
          })
        }
      } else {
        // 未排班人员页签不可编辑
        this.$message({
          message: '请选择已排班人员页签中需要修改排班的人员',
          type: 'warning'
        })
      }
    },
    delClick() {
      this.flagButton = 'del'
      // 删除排班

      if (this.tabName == 'second') {
        // 已排班人员

        if (this.multipleSelection.length > 0) {
          const ids = []
          for (var i = 0; i < this.multipleSelection.length; i++) {
            const item = this.multipleSelection[i]
            ids.push(item.personArrangeId)
          }

          this.$confirm('此操作将删除选中的人员排班, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$http.post('/Arrange/Personnel/BatchDelete', ids).then(res => {
              if (res.data.errcode > 0) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
                this.initAlreadyTableData(1)
              }
            }).catch(error => {
              this.$message.error('错误消息：' + error + '')
            })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            })
            this.$refs.multipleTable.clearSelection()
          })
        } else {
          this.$message({
            message: '请选中需要删除的人员排班',
            type: 'warning'
          })
        }
      } else {
        // 未排班人员 不可删除
        this.$message({
          message: '请选中已排班人员页签中需要删除的人员排班',
          type: 'warning'
        })
      }
    },
    saveClick() {
      // 保存排班
      const arranges = []
      for (var i = 0; i < this.multipleSelection.length; i++) {
        const item = this.multipleSelection[i]
        arranges.push({
          uuid: item.personArrangeId,
          person_id: item.id,
          idcard: item.shenfenzhenghaoma,
          w_arrange_id: this.selectArrange,
          t_dpt_id: this.dptId,
          isdel: 0
        })
      }

      if (this.flagButton == 'add') {
        this.$http.post('/Arrange/Personnel/BatchAdd', arranges).then(res => {
          if (res.data.errcode > 0) {
            this.$message({
              type: 'success',
              message: '排班成功!'
            })
            this.initNotTableDate(1)
          } else {
            this.$message.error('批量添加人员排班失败')
          }
        }).catch(error => {
          this.$message.error('错误消息：' + error + '')
        })
      } else if (this.flagButton == 'edit') {
        this.$http.post('/Arrange/Personnel/BatchEdit', arranges).then(res => {
          if (res.data.errcode > 0) {
            this.$message({
              type: 'success',
              message: '修改成功!'
            })
            this.initAlreadyTableData(1)
          } else {
            this.$message.error('批量编辑人员排班失败')
          }
        }).catch(error => {
          this.$message.error('错误消息：' + error + '')
        })
      }

      this.dialogFormVisible = false
    },
    notHandleSizeChange(val) {
      // 未排班：分页
      this.notQuery.page = 1
      this.notQuery.limit = val
    },
    notHandleCurrentChange(val) {
      // 未排班：分页
      this.notQuery.page = val
      this.initNotTableDate(val)
    },
    alreadyHandleSizeChange(val) {
      // 已排班：分页
      this.alreadyQuery.page = 1
      this.alreadyQuery.limit = val
    },
    alreadyHandleCurrentChange(val) {
      // 已排班：分页
      this.alreadyQuery.page = val
      this.initAlreadyTableData(val)
    },
    initTree() {
      this.$http.post('/Arrange/Personnel/GetTree').then(res => {
        if (res.data.errcode == 0) {
          this.dataTree = res.data.result
        }
      }).catch(error => {
        this.$message.error('错误消息：' + error + '')
      })
    },
    initArrange() {
      // 获取排班数据
      this.$http.get('/Arrange/Personnel/GetArrangeList').then(res => {
        if (res.data.errcode == 0) {
          const data = res.data.result
          this.arrangeOptions = data.list
        }
      }).catch(error => {
        this.$message.error('错误消息：' + error + '')
      })
    },
    initNotTableDate(pageIndex) {
      // 未排班数据

      this.notQuery.page = pageIndex

      this.$http.get('/Arrange/Personnel/GetNotPageList?page=' + this.notQuery.page + '&limit=' + this.notQuery
        .limit + '&name=' + this.telName + '&dptNames=' + this.dptNames).then(res => {
        if (res.data.errcode == 0) {
          const data = res.data.result
          this.notTotal = data.count
          this.notTableData = data.list
        }
      }).catch(error => {
        this.$message.error('错误消息：' + error + '')
      })
    },
    initAlreadyTableData(pageIndex) {
      // 已排班人员数据

      this.alreadyQuery.page = pageIndex

      this.$http.get('/Arrange/Personnel/GetAlreadyPageList?page=' + this.alreadyQuery.page + '&limit=' + this
        .alreadyQuery.limit + '&name=' + this.telName + '&dptNames=' + this.dptNames).then(res => {
        if (res.data.errcode == 0) {
          const data = res.data.result
          this.alreadyTotal = data.count
          this.alreadyTableData = data.list
        }
      }).catch(error => {
        this.$message.error('错误消息：' + error + '')
      })
    }
  }
}
</script>

<style lang="less" scoped>
	.app-container {
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	.container {
		flex: 1;
		display: flex;
		flex-direction: column;
		/*height: 100%;*/
		padding: 18px 8px 8px;

		.center-content {
			flex: 1;
		}
	}

	// .center-content {
	//     flex: 1;

	//     .el-scrollbar__wrap {
	//         overflow-x: hidden;
	//     }
	// }

	.user-statistics {
		display: flex;
		align-items: center;
		font-size: 16px;
		margin-bottom: 20px;

		.count {
			color: #f56c6c;
			font-weight: bold;
		}

		.user-total {}

		.user-today {
			margin-left: 20px;
		}
	}

	.el-main{
		position: relative;
	}
	
	.el-header {
		background-color: #FFF;
		color: #333;
		line-height: 60px;
	}

	.custom_button {
		// width: 800px;
		height: 50px;
		position: absolute;
		top: 0;
		right: 20px;
		z-index: 100;
	}

	.el-aside {
		color: #333;
		background: #fff !important;
		padding: 10px;
	}

</style>
