<!-- 智能管理 - 人员管理 -->
<template>
	<div class="app-container" ref="appContainer">
		<div class="container">
			<el-form ref="form" :model="faceConfig" label-width="200px">
				<el-form-item label="特征点置信度">
					<el-slider v-model="faceConfig.pointsQuality" :step="0.01" :min="0" :max="1" show-input></el-slider>
					<el-tag type="info">建议取值 >=0.85</el-tag>
				</el-form-item>
				<el-form-item label="瞳距">
					<el-slider v-model="faceConfig.eyeDistance" :step="0.01" :min="0" :max="100" show-input></el-slider>
					<el-tag type="info">建议取值 >=40.0</el-tag>
				</el-form-item>
				<el-form-item label="清晰度">
					<el-slider v-model="faceConfig.clearity" :step="0.01" :min="0" :max="1" show-input></el-slider>
					<el-tag type="info">建议取值 >=0.6</el-tag>
				</el-form-item>
				<el-form-item label="俯仰角范围最小值">
					<el-slider v-model="faceConfig.posePitchMin" :step="0.01" :min="-50" :max="0" show-input></el-slider>
					<el-tag type="info">建议取值 >=-15.0</el-tag>
				</el-form-item>
				<el-form-item label="俯仰角范围最大值">
					<el-slider v-model="faceConfig.posePitchMax" :step="0.01" :min="0" :max="50" show-input></el-slider>
					<el-tag type="info">建议取值 <=15.0</el-tag>
				</el-form-item>
				<el-form-item label="左右角范围最小值">
					<el-slider v-model="faceConfig.poseYawMin" :step="0.01" :min="-50" :max="0" show-input></el-slider>
					<el-tag type="info">建议取值 >=-20.0</el-tag>
				</el-form-item>
				<el-form-item label="左右角范围最大值">
					<el-slider v-model="faceConfig.poseYawMax" :step="0.01" :min="0" :max="50" show-input></el-slider>
					<el-tag type="info">建议取值 <=20.0</el-tag>
				</el-form-item>
				<el-form-item label="可见性">
					<el-slider v-model="faceConfig.uncovered" :step="0.01" :min="0" :max="1" show-input></el-slider>
					<el-tag type="info">建议取值 >=0.8</el-tag>
				</el-form-item>
				<el-form-item label="总评分">
					<el-slider v-model="faceConfig.totalQuality" :step="0.01" :min="0" :max="1" show-input></el-slider>
					<el-tag type="info">建议取值 >=0.6</el-tag>
				</el-form-item>
				<el-form-item>
					<el-input v-model="faceConfig.id" v-if="false"></el-input>
					<el-button type="primary" @click="saveFaceConfig">保存</el-button>
					<el-button type="primary" @click="resetFaceConfig">重置</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				faceConfig:{
					id:'',
					pointsQuality:0.85,
					eyeDistance:40,
					clearity:0.6,
					posePitchMin:-15,
					posePitchMax:15,
					poseYawMin:-20,
					poseYawMax:20,
					uncovered:0.8,
					totalQuality:0.6
				}
			}
		},
		
		mounted() {
			//加载人脸评分标准
			this.loadFaceConfig();
		},
		methods: {
			formatTooltip(val) {
				return val / 100;
			},
			loadFaceConfig(){
				this.$http.get('/BasicData/FaceConfig/GetFaceConfig').then(res => {
					if (res.data.errcode == 0) {
						this.faceConfig = res.data.data;
				  	} else {
				  		this.resetFaceConfig();
				  	}
				})
			},
			saveFaceConfig() {
				this.$http.post('/BasicData/FaceConfig/SaveFaceConfig',this.faceConfig).then(res => {
				    if (res.data.errcode == 0) {
				       this.$message({
				           message: '人脸评分标准配置成功',
				           type: 'success'
				       });
				    } else {
				        this.$message({
				        	message: res.data.errmsg,
				            type: 'error'
				        });
				   }
				})
			},
			resetFaceConfig() {
				//重置
				this.faceConfig={
					pointsQuality:0.85,
					eyeDistance:40,
					clearity:0.6,
					posePitchMin:-15,
					posePitchMax:15,
					poseYawMin:-20,
					poseYawMax:20,
					uncovered:0.8,
					totalQuality:0.6
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.app-container {
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	.container {
		flex: 1;
		display: flex;
		flex-direction: column;
		/*height: 100%;*/
		padding: 18px 8px 8px;
		width: 900px;
		// margin: 0px auto;
		
		::v-deep .el-form-item__content{
			display: flex;
			align-items: center;
			
			.el-slider{
				flex: 1;
			}
			
			.el-tag{
				width: 120px;
				margin-left: 15px;
			}
		}
	}
</style>
