<template>
  <div class="xm-main">
    <div class="xm-center">
      <div class="xm-center-content">
        <div class="xm-login-box">
          <el-form
            :model="formUser"
            :rules="rules"
            ref="ruleForm"
            class="login-form"
            :disabled="disabledLoginForm"
          >
            <div class="form-login-title">官湖高级中学</div>
            <el-form-item prop="phone">
              <el-input
                v-model="formUser.phone"
                size="large"
                clearable
                :disabled="loginLoading"
                placeholder="请输入您的账号"
                prefix-icon="el-icon-s-custom"
              ></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                show-password
                v-model="formUser.password"
                size="large"
                clearable
                :disabled="loginLoading"
                placeholder="请输入您的密码"
                prefix-icon="el-icon-lock"
              ></el-input>
            </el-form-item>
            <el-form-item class="btn-login-box">
              <el-button
                type="primary"
                style="width: 100%"
                :loading="loginLoading"
                @click="submitForm"
              >
                登录</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="xm-bottom">
      <div class="bottom-link-button">
        <a href="https://beian.miit.gov.cn">苏ICP备2021039474号</a> © 2021
        讯铭物联科技
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'login',
  data() {
    return {
      showLoginForm: true,

      formUser: {
        phone: '',
        password: ''
      },
      rules: {
        phone: [
          {
            required: true,
            message: '请输入您的账号',
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: '请输入您的密码',
            trigger: 'blur'
          }
        ]
      },
      loginLoading: false,
      disabledLoginForm: false
    }
  },
  created() {
    if (window.sessionStorage.getItem('token')) {
      this.$router.push('/home')
    }
  },
  mounted() {
    window.addEventListener('keydown', this.keyDown)
  },
  destroyed() {
    window.removeEventListener('keydown', this.keyDown, false)
  },
  methods: {
    submitForm() {
      // 调用validate函数来对表单数据进行预校验，函数参数是一个回调函数，回调函数第一个参数是一个变量，当校验通过是true，否则为false
      // 第二个参数是验证规则对象，哪个验证规则不通过obj就是这个规则所对应的对象
      this.$refs.ruleForm.validate(async (valid, obj) => {
        if (!valid) return
        // 对应接口文档说的是Post请求方式，携带两个必要数据{username和password}，把父数据对象直接传过去即可
        // const result=await this.$http.post('login',this.loginForm);
        // post的返回值result是一个Promise对象，可以用await来简化，但必须存在于async修饰的函数下

        this.loginLoading = true

        // 从result中将data属性结构出来重命名为res，data中存储着服务器响应的数据，其他属性暂不需要
        this.$http.post('/Login/Login', this.formUser).then((res) => {
          // Message的两种方式，一种是message函数传入对象；一种是将type当作方法传入提示语
          if (res.data.errcode !== 0) {
            this.loginLoading = false
            return this.$message({
              message: '用户名或密码错误，登录失败',
              type: 'error'
            })
          }

          this.$message.success('登录成功')
          window.sessionStorage.setItem('token', res.data.result.token)
          window.sessionStorage.setItem(
            'user',
            JSON.stringify(res.data.result.user)
          )
          // 通过编程式导航跳转到后台主页，地址：/home
          this.$router.push('/home')
        })
      })
    },
    keyDown(e) {
    //   如果是回车则执行登录方法
      if (e.keyCode === 13 && this.showLoginForm) {
        this.submitForm()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.xm-main {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  color: #fff;
  background-image: url('../assets/bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;

  .xm-top {
    display: flex;
    align-items: center;
    padding: 40px 100px;

    .xm-logo {
      padding-right: 20px;
      margin-right: 10px;
      font-size: 26px;
      font-family: Microsoft YaHei UI, Microsoft YaHei, SimSun, Tahoma,
        Helvetica, sans-serif;
      position: relative;

      ::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        background-color: #fff;
        width: 1px;
      }
    }

    .xm-title {
      font-size: 26px;
      font-family: Microsoft YaHei UI, Microsoft YaHei, SimSun, Tahoma,
        Helvetica, sans-serif;
    }
  }

  .xm-center {
    display: flex;
    align-items: center;
    padding: 10px 80px;
    justify-content: center;
    flex: 1;
    padding-bottom: 70px;

    .xm-center-content {
      display: flex;
      align-items: center;
    }

    .xm-promotion {
      flex: 1;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .xm-login-box {
      width: 300px;
      padding: 25px 50px;
      background: rgba(0, 0, 0, 0.45);
      border-radius: 15px;
      color: #666;
      font-size: 16px;
      display: flex;
      align-items: center;
      position: relative;
    }

    .change-login-type {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 20px;
      cursor: pointer;
    }

    .form-login-title {
      margin-bottom: 30px;
      text-align: center;
      color: #fff;
      font-size: 25px;
      letter-spacing: 3px;
    }

    .xm-login-box {
      form {
        flex: 1;

        .el-form-item {
          ::-webkit-input-placeholder {
            // WebKit browsers
            color: #878787;
          }

          :-moz-placeholder {
            // Mozilla Firefox 4 to 18
            color: #878787;
          }

          ::-moz-placeholder {
            // Mozilla Firefox 19+
            color: #878787;
          }

          :-ms-input-placeholder {
            // Internet Explorer 10+
            color: #878787;
          }
        }

        .btn-login-box {
          margin-top: 35px;
        }
      }
    }
  }

  .xm-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 10px;

    .bottom-link-button {
      /*padding: 0 15px;*/
      line-height: 1;
      position: relative;
      color: #c7c7c7;
      font-size: 13px;

      :not(:last-child)::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        background-color: #999;
        width: 1px;
      }

      a {
        color: inherit;
        text-decoration: none;

        :hover {
          color: #fff;
        }
      }
    }
  }
}
</style>
