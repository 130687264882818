<!-- 角色管理 -->
<template>
	<div class="app-container" ref="appContainer">
		<div class="container">
			<div class="top-bar">
				<el-button type="primary" @click="addRole">新增角色</el-button>
			</div>
			<div class="center-content" ref="tbContainer">
				<el-table ref="tbRoles" :data="tbRoles" border stripe class="table" :height="tbHeight"
					:highlight-current-row="true" :header-cell-style="{textAlign: 'center'}"
					:cell-style="{ textAlign: 'center' }">
					<el-table-column prop="id" label="ID" width="80">
					</el-table-column>
					<el-table-column prop="name" label="角色名称" width="180">
					</el-table-column>
					<el-table-column prop="remark" label="备注">
					</el-table-column>
					<el-table-column prop="isEnable" label="状态" width="100">
						<template slot-scope="scope">
							<i v-if="scope.row.isEnable == 1" class="el-icon-circle-check"
								style="color: #67C23A; font-size: 18px;"></i>
							<i v-else class="el-icon-circle-close" style="color: #F56C6C; font-size: 18px; "></i>
						</template>
					</el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-button size="mini" type="primary" @click="editRoleAuth(scope.row)">权限</el-button>
							<el-button size="mini" @click="editRole(scope.row)">编辑</el-button>
							<el-button size="mini" type="danger" @click="deleteRole(scope.row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>

		<el-dialog :title="addOrEditDiaTitle" :visible.sync="showAddOrEditDia" :close-on-click-modal="false"
			width="500px">
			<div>
				<el-form :model="formRole" :rules="rules" ref="formRole" label-width="90px">
					<el-form-item prop="name" label="角色名称">
						<el-input v-model="formRole.name" :clearable="true" size="large" placeholder="请输入角色名称">
						</el-input>
					</el-form-item>
					<el-form-item prop="remark" label="备注">
						<el-input type="textarea" v-model="formRole.remark" :clearable="true" size="large"
							placeholder="请输入备注"></el-input>
					</el-form-item>
					<el-form-item prop="isEnable" label="是否启用">
						<el-radio-group v-model="formRole.isEnable">
							<el-radio :label="1">启用</el-radio>
							<el-radio :label="0">禁用</el-radio>
						</el-radio-group>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancelAddOrEdit">取 消</el-button>
				<el-button type="primary" @click="confirmAddOrEdit">确 定</el-button>
			</span>
		</el-dialog>

		<el-drawer :title="`权限管理 - 【${clickedRole.name}】`" :visible.sync="showRoleAuthDia" direction="rtl"
			@closed="closedDrawerMenu" custom-class="drawer-role-menu">
			<div class="drawer-menu-container">
				<div class="menu-search">
					<el-form :inline="true" class="demo-form-inline" size="mini">
						<el-form-item>
							<el-input v-model="searchMenu" placeholder="菜单名称" clearable></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="filterMenu">查询</el-button>
							<el-button type="primary" @click="toggleCheckAllMenu">{{checkedAll?'全不选':'全选'}}</el-button>
						</el-form-item>
					</el-form>
				</div>

				<el-scrollbar style="flex: 1;">
					<el-tree class="menu-tree" ref="treeMenu" :data="menus" :props="defaultProps" show-checkbox
						:check-strictly="true" node-key="id" :highlight-current="true" default-expand-all
						:filter-node-method="filterNodeMenu" @check-change="checkChangeMenu">
					</el-tree>
				</el-scrollbar>

				<div class="menu-footer">
					<el-button @click="cancelRoleAuth">取 消</el-button>
					<el-button type="primary" @click="confirmRoleAuth">确 定</el-button>
				</div>
			</div>
		</el-drawer>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				loadingData: true,
				tbRoles: [],
				tbHeight: 300,

				addOrEditDiaTitle: '新增角色',
				showAddOrEditDia: false,

				formRole: {
					id: 0,
					name: '',
					remark: '',
					isEnable: 1
				},
				rules: {
					name: [{
						required: true,
						message: '请输入角色名称',
						trigger: 'blur'
					}]
				},

				clickedRole: '',
				showRoleAuthDia: false,
				roleAuths: [],
				menus: [],
				allMenuIds: [],
				searchMenu: '',
				defaultProps: {
					children: 'children',
					label: 'name'
				},

				checkedAll: false,
			}
		},
		mounted() {
			this.loadData();
			this.getAllMenus();

			this.$erd.listenTo(this.$refs.appContainer, (element) => {
				this.tbHeight = 100;
				this.$nextTick(() => {
					this.tbHeight = this.$refs.tbContainer.offsetHeight;
				})
			})
		},
		methods: {
			getAllMenus() { // 获取所有菜单树
				this.$http.post('/SysManage/Menu/GetMenuTree').then(res => {
					if (res.data.errcode == 0) {
						this.menus = res.data.result;

						this.getAllMenuIds(this.menus)
					}
				})
			},
			getAllMenuIds(menus) {
				this.allMenuIds = this.allMenuIds.concat(menus.map(m => {
					return m.id;
				}));

				for (const menu of menus) {
					menu.children && menu.children.length > 0 && this.getAllMenuIds(menu.children)
				}
			},
			toggleCheckAllMenu() {
				this.checkedAll = !this.checkedAll;

				if (this.checkedAll)
					this.$refs.treeMenu.setCheckedKeys(this.allMenuIds);
				else
					this.$refs.treeMenu.setCheckedKeys([]);
			},
			filterNodeMenu(value, data) {
				if (!value) return true;
				return data.name.indexOf(value) !== -1;
			},
			filterMenu() {
				this.$refs.treeMenu.filter(this.searchMenu);
			},
			checkChangeMenu(data, check) {
				// 父节点操作
				if (data.pid !== 0) {
					if (check === true) {
						// 如果选中，设置父节点为选中
						this.$refs.treeMenu.setChecked(data.pid, true);
					} else {
						// 如果取消选中，检查父节点是否该取消选中（可能仍有子节点为选中状态）
						var parentNode = this.$refs.treeMenu.getNode(data.pid);
						var parentHasCheckedChild = false;
						for (
							var i = 0, parentChildLen = parentNode.childNodes.length; i < parentChildLen; i++
						) {
							if (parentNode.childNodes[i].checked === true) {
								parentHasCheckedChild = true;
								break;
							}
						}
						if (!parentHasCheckedChild)
							this.$refs.treeMenu.setChecked(data.pid, false);
					}
				}
				// 子节点操作，如果取消选中，取消子节点选中
				if (data.children != null && check === false) {
					for (var j = 0, len = data.children.length; j < len; j++) {
						var childNode = this.$refs.treeMenu.getNode(data.children[j].id);
						if (childNode.checked === true) {
							this.$refs.treeMenu.setChecked(childNode.data.id, false);
						}
					}
				}
			},
			loadData() { // 获取所有角色
				this.$http.post('/SysManage/Role/GetRoles').then(res => {
					if (res.data.errcode == 0) {
						this.tbRoles = res.data.result;
					}
				})
			},
			addRole() { // 添加角色
				this.formRole = {
					id: 0,
					name: '',
					remark: '',
					isEnable: 1
				};
				this.addOrEditDiaTitle = '新增角色';
				this.showAddOrEditDia = true;
				this.$nextTick(() => {
					this.$refs.formRole.resetFields();
				})
			},
			editRole(role) { // 编辑角色
				this.formRole = JSON.parse(JSON.stringify(role));
				this.addOrEditDiaTitle = '编辑角色';
				this.showAddOrEditDia = true;
				this.$refs.tbRoles.setCurrentRow(role)
			},
			deleteRole(role) {
				this.$refs.tbRoles.setCurrentRow(role)
				this.$confirm('确定要删除该角色吗', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					closeOnClickModal: false,
				}).then(() => {
					this.$http.post('/SysManage/Role/DeleteRole', {
						id: role.id
					}).then(res => {
						if (res.data.errcode == 0) {
							let idx = this.tbRoles.findIndex(r => r.id == role.id);
							this.tbRoles.splice(idx, 1);

							this.$message({
								message: '删除成功！',
								type: 'success'
							});
						}
					})
				}).catch(() => {

				});
			},
			cancelAddOrEdit() { // 取消，关闭对话框
				this.$refs.formRole.resetFields();
				this.showAddOrEditDia = false;
			},
			confirmAddOrEdit() {
				this.$refs.formRole.validate((valid) => {
					if (valid) {
						if (this.formRole.id > 0) {
							this.$http.post('/SysManage/Role/UpdateRole', this.formRole).then(res => {
								if (res.data.errcode == 0) {
									this.$message({
										message: '修改成功',
										type: 'success'
									});

									this.loadData();

									this.cancelAddOrEdit();
								}
							})
						} else {
							this.$http.post('/SysManage/Role/AddRole', this.formRole).then(res => {
								if (res.data.errcode == 0) {
									this.$message({
										message: '添加成功',
										type: 'success'
									});

									this.loadData();

									this.cancelAddOrEdit();
								}
							})
						}
					} else {
						return false;
					}
				});
			},
			editRoleAuth(role) {
				this.$http.post('/SysManage/Role/GetRoleAuth', {
					id: role.id
				}).then(res => {
					if (res.data.errcode == 0) {
						this.roleAuths = res.data.result;
						this.$refs.treeMenu.setCheckedKeys(this.roleAuths);
					}
				})
				this.clickedRole = role;
				this.showRoleAuthDia = true;
				this.$refs.tbRoles.setCurrentRow(role)
			},
			cancelRoleAuth() {
				this.showRoleAuthDia = false;
				let that = this;
				setTimeout(() => {
					that.clickedRole = '';
					that.roleAuths = [];
				}, 300)
			},
			confirmRoleAuth() {
				if (this.clickedRole) {
					this.roleAuths = [
						...this.$refs.treeMenu.getHalfCheckedKeys(),
						...this.$refs.treeMenu.getCheckedKeys()
					]
					let loading = this.$loading();
					this.$http.post('/SysManage/Role/SaveRoleAuth', {
						id: this.clickedRole.id,
						menuIds: this.roleAuths
					}).then(res => {
						loading.close();
						if (res.data.errcode == 0) {
							this.$message({
								message: '修改成功',
								type: 'success'
							});
							this.cancelRoleAuth();
						}
					})
				}
			},
			closedDrawerMenu() {
				this.$refs.treeMenu.setCheckedKeys([]);
				this.checkedAll = false;
			}
		}
	}
</script>

<style lang="less" scoped>
	.app-container {
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	.container {
		flex: 1;
		display: flex;
		flex-direction: column;
		padding: 18px 8px 8px;

		.center-content {
			flex: 1;
		}
	}

	.top-bar {
		margin-bottom: 15px;
	}

	.center-content {
		flex: 1;
	}

	.table {
		width: 100%;
	}

	.drawer-role-menu {
		min-width: 400px;
	}

	.drawer-menu-container {
		height: 100%;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		padding-left: 15px;
		
		::v-deep .el-scrollbar__wrap {
			overflow-x: hidden;
		}
	}

	.drawer-menu-container .menu-search {
		margin-bottom: 15px;
		border-bottom: 1px solid #e6e6e6;
	}

	.drawer-menu-container .menu-footer {
		padding-top: 15px;
		margin-top: 15px;
		border-top: 1px solid #e6e6e6;
		text-align: right;
	}
</style>
